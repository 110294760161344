import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosPrivateInstance } from 'libs/axios';

import { useAppDispatch } from 'hooks/stateHooks';
import { GET_PRODUCT_IMPORT_REQUEST_DETAILS_VIEW } from 'utils/serviceUrls';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';

const useGetAllProductFileDetails = (productFileId: any, refreshGrid: any) => {
	const { t } = useTranslation('ProductFileModule');
	const dispatch = useAppDispatch();

	const [productFileDetailsList, setProductFileDetailsList] = useState<any>([]);
	const [productFileDetailsLoading, setProductFileDetailsLoading] = useState<boolean>(true);

	const ApiRes = async (requestID: any) => {
		setProductFileDetailsLoading(true);
		await axiosPrivateInstance
			.post(GET_PRODUCT_IMPORT_REQUEST_DETAILS_VIEW, { encRequestID: requestID })
			.then((response: any) => {
				if (response?.output && response?.status) {
					setProductFileDetailsList(response?.output);
				} else {
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType('error'));
				}
			})
			.catch(() => {
				dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
				dispatch(setShowNotification(true));
				dispatch(setNotificationType('error'));
			})
			.finally(() => {
				setProductFileDetailsLoading(false);
			});
	};
	/**
	 * Initial API call
	 */
	useEffect(() => {
		if (productFileId) {
			ApiRes(productFileId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productFileId]);

	/**
	 * API call on refresh button click
	 */
	useEffect(() => {
		if (refreshGrid) {
			ApiRes(productFileId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refreshGrid, productFileId]);

	return {
		productFileDetailsList,
		productFileDetailsLoading,
	};
};

export default useGetAllProductFileDetails;
