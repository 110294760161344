import { useAppSelector } from 'hooks/stateHooks';
import { useTranslation } from 'react-i18next';
import { getEnable24Hr } from 'slices/configSlice';
import { NOTIFICATION_TYPES } from 'utils/enum';
import { utcTime } from 'utils/utils';

const NotificationTextCreation = (typeValue: any, item: any) => {
	const { t } = useTranslation('notification');
	const enable24Hr = useAppSelector(getEnable24Hr);
	const { createdOn, remarks } = item;
	// eslint-disable-next-line react/destructuring-assignment
	const customName1 = item?.customName1 ? item?.customName1 : '';
	// eslint-disable-next-line react/destructuring-assignment
	const customName2 = item?.customName2 ? item?.customName2 : '';
	// eslint-disable-next-line react/destructuring-assignment
	const customName3 = item?.customName3 ? item?.customName3 : '';
	// eslint-disable-next-line react/destructuring-assignment
	const customName4 = item?.customName4 ? item?.customName4 : '';

	switch (typeValue) {
		case NOTIFICATION_TYPES.USER_INVALID_LOGIN_ATTEMPT:
			return (
				<div>
					{t('AN_UNAUTHORIZED_ACCESS_ATTEMPT_HAS_BEEN_DETECTED')} {t('AT')}{' '}
					{utcTime(createdOn, enable24Hr)} {t('FROM')} <b>{customName3}</b>.{' '}
					{t('THE_ATTEMPT_WAS_MADE_THROUGH_THE_BROWSER')} <b>{customName2}</b>{' '}
					{t('USING_THE')} <b>{customName4}</b>. {t('ATTEMPTED_USER_ID')} {customName1}.
				</div>
			);
		case NOTIFICATION_TYPES.EMAIL_AUTHENTICATION_FAILED:
			return (
				<div>
					{t('AN_UNAUTHORIZED_ACCESS_ATTEMPT_HAS_BEEN_DETECTED')} {t('AT')}{' '}
					{utcTime(createdOn, enable24Hr)} {t('FROM')} <b>{customName3}</b>.{' '}
					{t('THE_ATTEMPT_WAS_MADE_THROUGH_THE_BROWSER')} <b>{customName2}</b>{' '}
					{t('USING_THE')} <b>{customName4}</b>. {t('ATTEMPTED_EMAIL_ID')} {customName1}
				</div>
			);
		case NOTIFICATION_TYPES.PRODUCT_REVIEW_NOTIFICATION:
			return (
				<div>
					{t('THE_PRODUCT')} <b>{customName1}</b>{' '}
					{t('HAS_BEEN_REVIEWED_SUCCESSFULLY_BY_THE_REVIEWER')}
				</div>
			);
		case NOTIFICATION_TYPES.PRODUCT_APPROVE_NOFICATION:
			return (
				<div>
					{t('THE_PRODUCT')} <b>{customName1}</b>{' '}
					{t('HAS_BEEN_APPROVED_SUCCESSFULLY_BY_THE_APPROVER')}
				</div>
			);

		case NOTIFICATION_TYPES.PRODUCT_DEACTIVATION_REVIEW_NOTIFICATION:
			return (
				<div>
					{t('THE_DEACTIVATION_OF_PRODUCT')} <b>{customName1}</b>{' '}
					{t('HAS_BEEN_REVIEWED_SUCCESSFULLY_BY_THE_REVIEWER')}
				</div>
			);
		case NOTIFICATION_TYPES.PRODUCT_DEACTIVATION_APPROVAL_NOTIFICATION:
			return (
				<div>
					{t('THE_DEACTIVATION_OF_PRODUCT')} <b>{customName1}</b>{' '}
					{t('HAS_BEEN_APPROVED_SUCCESSFULLY_BY_THE_APPROVER')}
				</div>
			);
		// case NOTIFICATION_TYPES.'Product_Activation_Notification':
		// 	return (
		// 		<div>
		// 			The Product <b>{customName1}</b> has been reviewed successfully by the reviewer.
		// 		</div>
		// 	);
		case NOTIFICATION_TYPES.PRODUCT_ACTIVATION_REVIEW_NOTIFICATION:
			return (
				<div>
					{t('THE_ACTIVATION_OF_PRODUCT')} <b>{customName1}</b>{' '}
					{t('HAS_BEEN_REVIEWED_SUCCESSFULLY_BY_THE_REVIEWER')}
				</div>
			);
		case NOTIFICATION_TYPES.PRODUCT_ACTIVATION_APPROVAL_NOTIFICATION:
			return (
				<div>
					{t('THE_ACTIVATION_OF_PRODUCT')} <b>{customName1}</b>{' '}
					{t('HAS_BEEN_APPROVED_SUCCESSFULLY_BY_THE_APPROVER')}
				</div>
			);

		// case NOTIFICATION_TYPES.'PO_Creation_Notification':
		// 	return (
		// 		<div>
		// 			The Product <b>{customName1}</b> has been reviewed successfully by the reviewer.
		// 		</div>
		// 	);
		case NOTIFICATION_TYPES.PO_REVIEW_NOTIFICATION:
			return (
				<div>
					{t('THE_PRODUCR_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('HAS_BEEN_REVIEWED_SUCCESSFULLY_BY_THE_REVIEWER')}
				</div>
			);
		case NOTIFICATION_TYPES.PO_APPROVE_NOFICATION:
			return (
				<div>
					{t('THE_PRODUCR_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('HAS_BEEN_APPROVED_SUCCESSFULLY_BY_THE_APPROVER')}
				</div>
			);
		// case NOTIFICATION_TYPES.'PO_Deactivation_Notification':
		// 	return (
		// 		<div>
		// 			The Product <b>{customName1}</b> has been reviewed successfully by the reviewer.
		// 		</div>
		// 	);
		case NOTIFICATION_TYPES.PO_DEACTIVATION_REVIEW_NOTIFICATION:
			return (
				<div>
					{t('THE_DEACTIVATION_OF_PRODUCT_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('HAS_BEEN_REVIEWED_SUCCESSFULLY_BY_THE_REVIEWER')}
				</div>
			);
		case NOTIFICATION_TYPES.PO_DEACTIVATION_APPROVAL_NOTIFICATION:
			return (
				<div>
					{t('THE_DEACTIVATION_OF_PRODUCT_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('HAS_BEEN_APPROVED_SUCCESSFULLY_BY_THE_APPROVER')}
				</div>
			);
		case NOTIFICATION_TYPES.WEBLINK_ACTIVATION_NOTIFICATION:
			return (
				<div>
					{t('THE_PRODUCT')} <b>{customName1}</b>{' '}
					{t('HAS_BEEN_REVIEWED_SUCCESSFULLY_BY_THE_REVIEWER')}
				</div>
			);
		case NOTIFICATION_TYPES.WEBLINK_ACTIVATION_REVIEW_NOTIFICATION:
			return (
				<div>
					{t('THE_WEBLINK_ACTIVATION')} <b>{customName1}</b>{' '}
					{t('HAS_BEEN_REVIEWED_SUCCESSFULLY_BY_THE_REVIEWER')}
				</div>
			);
		case NOTIFICATION_TYPES.WEBLINK_ACTIVATION_APPROVAL_NOTIFICATION:
			return (
				<div>
					{t('THE_WEBLINK_ACTIVATION')} <b>{customName1}</b>{' '}
					{t('HAS_BEEN_APPROVED_SUCCESSFULLY_BY_THE_APPROVER')}
				</div>
			);
		// case NOTIFICATION_TYPES.'Weblink_Deactivation_Notification':
		// 	return (
		// 		<div>
		// 			The Product <b>{customName1}</b> has been reviewed successfully by the reviewer.
		// 		</div>
		// 	);
		case NOTIFICATION_TYPES.WEBLINK_DEACTIVATION_REVIEW_NOTIFICATION:
			return (
				<div>
					{t('THE_WEBLINK_DEACTIVATION')} <b>{customName1}</b>{' '}
					{t('HAS_BEEN_REVIEWED_SUCCESSFULLY_BY_THE_REVIEWER')}
				</div>
			);
		case NOTIFICATION_TYPES.WEBLINK_DEACTIVATION_APPROVAL_NOTIFICATION:
			return (
				<div>
					{t('THE_WEBLINK_DEACTIVATION')} <b>{customName1}</b>{' '}
					{t('HAS_BEEN_APPROVED_SUCCESSFULLY_BY_THE_APPROVER')}
				</div>
			);
		// case NOTIFICATION_TYPES.'PO_Activation_Notification':
		// 	return (
		// 		<div>
		// 			The Product <b>{customName1}</b> has been reviewed successfully by the reviewer.
		// 		</div>
		// 	);
		case NOTIFICATION_TYPES.PO_ACTIVATION_REVIEW_NOTIFICATION:
			return (
				<div>
					{t('THE_ACTIVATION_OF_PRODUCT_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('HAS_BEEN_REVIEWED_SUCCESSFULLY_BY_THE_REVIEWER')}
				</div>
			);
		case NOTIFICATION_TYPES.PO_ACTIVATION_APPROVAL_NOTIFICATION:
			return (
				<div>
					{t('THE_ACTIVATION_OF_PRODUCT_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('HAS_BEEN_APPROVED_SUCCESSFULLY_BY_THE_APPROVER')}
				</div>
			);

		// Awaiting Action Text

		case NOTIFICATION_TYPES.PRODUCT_REVIEW_NOTIFICATION_ACTION:
			return (
				<div>
					{t('THE_PRODUCT')} <b>{customName1}</b> {t('CREATED_AND_WAITING_FOR_REVIEW')}
				</div>
			);

		case NOTIFICATION_TYPES.PRODUCT_APPROVE_NOFICATION_ACTION:
			return (
				<div>
					{t('THE_PRODUCT')} <b>{customName1}</b> {t('CREATED_AND_WAITING_FOR_APPROVAL')}
				</div>
			);

		case NOTIFICATION_TYPES.PRODUCT_ACTIVATION_APPROVAL_NOTIFICATION_ACTION:
			return (
				<div>
					{t('ACTIVATION_INITIATED_FOR_THE_PRODUCT')} <b>{customName1}</b>{' '}
					{t('WAITING_FOR_THE_APPROVAL')}
				</div>
			);

		case NOTIFICATION_TYPES.PRODUCT_ACTIVATION_REVIEW_NOTIFICATION_ACTION:
			return (
				<div>
					{t('ACTIVATION_INITIATED_FOR_THE_PRODUCT')} <b>{customName1}</b>{' '}
					{t('WAITING_FOR_THE_REVIEW')}
				</div>
			);

		case NOTIFICATION_TYPES.PRODUCT_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION:
			return (
				<div>
					{t('DEACTIVATION_INITIATED_FOR_THE_PRODUCT')} <b>{customName1}</b>{' '}
					{t('WAITING_FOR_THE_APPROVAL')}
				</div>
			);

		case NOTIFICATION_TYPES.PRODUCT_DEACTIVATION_REVIEW_NOTIFICATION_ACTION:
			return (
				<div>
					{t('DEACTIVATION_INITIATED_FOR_THE_PRODUCT')} <b>{customName1}</b>{' '}
					{t('WAITING_FOR_THE_REVIEW')}
				</div>
			);

		case NOTIFICATION_TYPES.PO_APPROVE_NOFICATION_ACTION:
			return (
				<div>
					{t('THE_PRODUCR_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('CREATED_AND_WAITING_FOR_APPROVAL')}
				</div>
			);

		case NOTIFICATION_TYPES.PO_REVIEW_NOTIFICATION_ACTION:
			return (
				<div>
					{t('THE_PRODUCR_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('CREATED_AND_WAITING_FOR_REVIEW')}
				</div>
			);

		case NOTIFICATION_TYPES.PO_ACTIVATION_REVIEW_NOTIFICATION_ACTION:
			return (
				<div>
					{t('ACTIVATION_INITIATED_FOR_THE_PRODUCT_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('WAITING_FOR_THE_REVIEW')}
				</div>
			);

		case NOTIFICATION_TYPES.PO_ACTIVATION_APPROVAL_NOTIFICATION_ACTION:
			return (
				<div>
					{t('ACTIVATION_INITIATED_FOR_THE_PRODUCT_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('WAITING_FOR_THE_APPROVAL')}
				</div>
			);

		case NOTIFICATION_TYPES.PO_DEACTIVATION_REVIEW_NOTIFICATION_ACTION:
			return (
				<div>
					{t('DEACTIVATION_INITIATED_FOR_THE_PRODUCT_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('WAITING_FOR_THE_REVIEW')}
				</div>
			);

		case NOTIFICATION_TYPES.PO_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION:
			return (
				<div>
					{t('DEACTIVATION_INITIATED_FOR_THE_PRODUCT_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('WAITING_FOR_THE_APPROVAL')}
				</div>
			);

		case NOTIFICATION_TYPES.WEBLINK_ACTIVATION_APPROVAL_NOTIFICATION_ACTION:
			return (
				<div>
					{t('ACTIVATION_INITIATED_FOR_THE_PRODUCT')} <b>{customName1}</b>{' '}
					{t('WEBLINK_AND_WAITING_FOR_APPROVAL')}
				</div>
			);

		case NOTIFICATION_TYPES.WEBLINK_ACTIVATION_REVIEW_NOTIFICATION_ACTION:
			return (
				<div>
					{t('ACTIVATION_INITIATED_FOR_THE_PRODUCT')} <b>{customName1}</b>{' '}
					{t('WEBLINK_AND_WAITING_FOR_REVIEW')}
				</div>
			);

		case NOTIFICATION_TYPES.WEBLINK_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION:
			return (
				<div>
					{t('DEACTIVATION_INITIATED_FOR_THE_PRODUCT')} <b>{customName1}</b>{' '}
					{t('WEBLINK_AND_WAITING_FOR_APPROVAL')}
				</div>
			);

		case NOTIFICATION_TYPES.WEBLINK_DEACTIVATION_REVIEW_NOTIFICATION_ACTION:
			return (
				<div>
					{t('DEACTIVATION_INITIATED_FOR_THE_PRODUCT')} <b>{customName1}</b>{' '}
					{t('WEBLINK_AND_WAITING_FOR_REVIEW')}
				</div>
			);

		case NOTIFICATION_TYPES.PO_WEBLINK_ACTIVATION_REVIEW_NOTIFICATION_ACTION:
			return (
				<div>
					{t('ACTIVATION_INITIATED_FOR_THE_PRODUCT_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('WEBLINK_AND_WAITING_FOR_REVIEW')}
				</div>
			);

		case NOTIFICATION_TYPES.PO_WEBLINK_ACTIVATION_APPROVAL_NOTIFICATION_ACTION:
			return (
				<div>
					{t('ACTIVATION_INITIATED_FOR_THE_PRODUCT_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('WEBLINK_AND_WAITING_FOR_APPROVAL')}
				</div>
			);

		case NOTIFICATION_TYPES.PO_WEBLINK_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION:
			return (
				<div>
					{t('DEACTIVATION_INITIATED_FOR_THE_PRODUCT_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('WEBLINK_AND_WAITING_FOR_APPROVAL')}
				</div>
			);

		case NOTIFICATION_TYPES.PO_WEBLINK_DEACTIVATION_REVIEW_NOTIFICATION_ACTION:
			return (
				<div>
					{t('DEACTIVATION_INITIATED_FOR_THE_PRODUCT_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('WEBLINK_AND_WAITING_FOR_REVIEW')}
				</div>
			);

		case NOTIFICATION_TYPES.PO_WEBLINK_ACTIVATION_REVIEW_NOTIFICATION:
			return (
				<div>
					{t('WEBLINK_ACTIVATION_PRODUCT_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('HAS_BEEN_REVIEWED_SUCCESSFULLY_BY_THE_REVIEWER')}
				</div>
			);
		case NOTIFICATION_TYPES.PO_WEBLINK_ACTIVATION_APPROVAL_NOTIFICATION:
			return (
				<div>
					{t('WEBLINK_ACTIVATION_PRODUCT_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('HAS_BEEN_APPROVED_SUCCESSFULLY_BY_THE_APPROVER')}
				</div>
			);

		case NOTIFICATION_TYPES.PO_WEBLINK_DEACTIVATION_REVIEW_NOTIFICATION:
			return (
				<div>
					{t('WEBLINK_DEACTIVATION_PRODUCT_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('HAS_BEEN_REVIEWED_SUCCESSFULLY_BY_THE_REVIEWER')}
				</div>
			);
		case NOTIFICATION_TYPES.PO_WEBLINK_DEACTIVATION_APPROVAL_NOTIFICATION:
			return (
				<div>
					{t('WEBLINK_DEACTIVATION_PRODUCT_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('HAS_BEEN_APPROVED_SUCCESSFULLY_BY_THE_APPROVER')}
				</div>
			);
		case NOTIFICATION_TYPES.PO_WEBLINK_REVIEW_REJECT_NOTIFICATION:
			return (
				<div>
					{t('WEBLINK_ACTIVATION_REVIEW_PRODUCT_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('REJECTED_BY_REVIEWER')} {t('REMARKS')} {remarks}
				</div>
			);
		case NOTIFICATION_TYPES.PO_WEBLINK_APPROVE_REJECT_NOTIFICATION:
			return (
				<div>
					{t('WEBLINK_ACTIVATION_REVIEW_PRODUCT_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('REJECTED_BY_APPROVER')} {t('REMARKS')} {remarks}
				</div>
			);
		case NOTIFICATION_TYPES.PO_WEBLINK_DEACTIVATION_REVIEW_REJECT_NOTIFICATION:
			return (
				<div>
					{t('WEBLINK_DEACTIVATION_REVIEW_PRODUCT_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('REJECTED_BY_REVIEWER')} {t('REMARKS')} {remarks}
				</div>
			);
		case NOTIFICATION_TYPES.PO_WEBLINK_DEACTIVATION_APPROVAL_REJECT_NOTIFICATION:
			return (
				<div>
					{t('WEBLINK_DEACTIVATION_REVIEW_PRODUCT_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('REJECTED_BY_APPROVER')} {t('REMARKS')} {remarks}
				</div>
			);
		case NOTIFICATION_TYPES.PO_WEBLINK_ACTIVATION_REVIEW_REJECT_NOTIFICATION:
			return (
				<div>
					{t('WEBLINK_ACTIVATION_REVIEW_PRODUCT_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('REJECTED_BY_REVIEWER')} {t('REMARKS')} {remarks}
				</div>
			);
		case NOTIFICATION_TYPES.PO_WEBLINK_ACTIVATION_APPROVAL_REJECT_NOTIFICATION:
			return (
				<div>
					{t('WEBLINK_ACTIVATION_REVIEW_PRODUCT_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('REJECTED_BY_APPROVER')} {t('REMARKS')} {remarks}
				</div>
			);
		case NOTIFICATION_TYPES.PO_REVIEW_REJECT_NOTIFICATION:
			return (
				<div>
					{t('THE_PRODUCR_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('RETURN_BY_REVIEWER')} {t('REMARKS')} {remarks}
				</div>
			);
		case NOTIFICATION_TYPES.PO_APPROVE_REJECT_NOTIFICATION:
			return (
				<div>
					{t('THE_PRODUCR_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('RETURN_BY_APPROVER')} {t('REMARKS')} {remarks}
				</div>
			);
		case NOTIFICATION_TYPES.WEBLINK_REVIEW_REJECT_NOTIFICATION:
			return (
				<div>
					{t('THE_WEBLINK')} <b>{customName1}</b> {t('RETURN_BY_REVIEWER')} {t('REMARKS')}{' '}
					{remarks}
				</div>
			);

		case NOTIFICATION_TYPES.WEBLINK_DEACTIVATION_REVIEW_REJECT_NOTIFICATION:
			return (
				<div>
					{t('WEBLINK_DEACTIVATION_REVIEW_OF')} <b>{customName1}</b>{' '}
					{t('RETURN_BY_REVIEWER')} {t('REMARKS')} {remarks}
				</div>
			);
		case NOTIFICATION_TYPES.WEBLINK_DEACTIVATION_APPROVAL_REJECT_NOTIFICATION:
			return (
				<div>
					{t('WEBLINK_DEACTIVATION_REVIEW_OF')} <b>{customName1}</b>{' '}
					{t('RETURN_BY_APPROVER')} {t('REMARKS')} {remarks}
				</div>
			);
		case NOTIFICATION_TYPES.WEBLINK_ACTIVATION_REVIEW_REJECT_NOTIFICATION:
			return (
				<div>
					{t('WEBLINK_ACTIVATION_REVIEW_OF')} <b>{customName1}</b>{' '}
					{t('RETURN_BY_REVIEWER')} {t('REMARKS')} {remarks}
				</div>
			);
		case NOTIFICATION_TYPES.WEBLINK_ACTIVATION_APPROVAL_REJECT_NOTIFICATION:
			return (
				<div>
					{t('WEBLINK_ACTIVATION_REVIEW_OF')} <b>{customName1}</b>{' '}
					{t('RETURN_BY_APPROVER')} {t('REMARKS')} {remarks}
				</div>
			);
		case NOTIFICATION_TYPES.WEBLINK_APPROVE_REJECT_NOTIFICATION:
			return (
				<div>
					{t('THE_PRODUCR_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('RETURN_BY_APPROVER')} {t('REMARKS')} {remarks}
				</div>
			);
		case NOTIFICATION_TYPES.PRODUCT_APPROVE_REJECT_NOTIFICATION:
			return (
				<div>
					{t('THE_PRODUCT')} <b>{customName1}</b> {t('RETURN_BY_APPROVER')} {t('REMARKS')}{' '}
					{remarks}
				</div>
			);
		case NOTIFICATION_TYPES.PO_DEACTIVATION_REVIEW_REJECT_NOTIFICATION:
			return (
				<div>
					{t('THE_PRODUCR_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('DEACTIVATION_REVIEWER_RETURNED')} {t('REMARKS')} {remarks}
				</div>
			);
		case NOTIFICATION_TYPES.PO_DEACTIVATION_APPROVAL_REJECT_NOTIFICATION:
			return (
				<div>
					{t('THE_PRODUCR_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('DEACTIVATION_APPROVER_RETURNED')} {t('REMARKS')} {remarks}
				</div>
			);
		case NOTIFICATION_TYPES.PO_ACTIVATION_REVIEW_REJECT_NOTIFICATION:
			return (
				<div>
					{t('THE_PRODUCR_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('ACTIVATION_REVIEWER_RETURNED')} {t('REMARKS')} {remarks}
				</div>
			);
		case NOTIFICATION_TYPES.PO_ACTIVATION_APPROVAL_REJECT_NOTIFICATION:
			return (
				<div>
					{t('THE_PRODUCR_ORDER')}{' '}
					<b>
						{customName1} - {customName2}
					</b>{' '}
					{t('ACTIVATION_APPROVER_RETURNED')} {t('REMARKS')} {remarks}
				</div>
			);
		case NOTIFICATION_TYPES.PRODUCT_ACTIVATION_APPROVAL_REJECT_NOTIFICATION:
			return (
				<div>
					{t('THE_PRODUCT')} <b>{customName1}</b> {t('ACTIVATION_APPROVER_RETURNED')}{' '}
					{t('REMARKS')} {remarks}
				</div>
			);
		case NOTIFICATION_TYPES.PRODUCT_ACTIVATION_REVIEW_REJECT_NOTIFICATION:
			return (
				<div>
					{t('THE_PRODUCT')} <b>{customName1}</b> {t('ACTIVATION_REVIEWER_RETURNED')}{' '}
					{t('REMARKS')} {remarks}
				</div>
			);
		case NOTIFICATION_TYPES.PRODUCT_REVIEW_REJECT_NOTIFICATION:
			return (
				<div>
					{t('THE_PRODUCT')} <b>{customName1}</b> {t('RETURN_BY_REVIEWER')} {t('REMARKS')}
					{remarks}
				</div>
			);
		case NOTIFICATION_TYPES.PRODUCT_DEACTIVATION_REVIEW_REJECT_NOTIFICATION:
			return (
				<div>
					{t('THE_PRODUCT')} <b>{customName1}</b> {t('DEACTIVATION_REVIEWER_RETURNED')}{' '}
					{t('REMARKS')} {remarks}
				</div>
			);
		case NOTIFICATION_TYPES.PRODUCT_DEACTIVATION_APPROVAL_REJECT_NOTIFICATION:
			return (
				<div>
					{t('THE_PRODUCT')} <b>{customName1}</b> {t('DEACTIVATION_APPROVER_RETURNED')}{' '}
					{t('REMARKS')} {remarks}
				</div>
			);
		case NOTIFICATION_TYPES.REPORT_REVIEW_NOTIFICATION_ACTION:
			return (
				<div>
					{t('THE')} <b>{customName1}</b> {t('REPORT_GENERATED_AND_WAITING_FOR_REVIEW')}
				</div>
			);

		case NOTIFICATION_TYPES.REPORT_APPROVE_NOFICATION_ACTION:
			return (
				<div>
					{t('THE')} <b>{customName1}</b> {t('REPORT_REVIEWED_AND_WAITING_FOR_APPROVAL')}
				</div>
			);
		case NOTIFICATION_TYPES.REPORT_REVIEW_NOTIFICATION:
			return (
				<div>
					{t('THE')} <b>{customName1}</b> {t('REPORT_REVIEWED')} <b>{customName2}</b>.
				</div>
			);
		case NOTIFICATION_TYPES.REPORT_APPROVE_NOTIFICATION:
			return (
				<div>
					{t('THE')} <b>{customName1}</b> {t('REPORT_APPROVED')} <b>{customName2}</b>.
				</div>
			);
		// case NOTIFICATION_TYPES.REPORT_REVIEW_RETURN_NOTIFICATION:
		// 	return (
		// 		<div>
		// 			{t('THE')} <b>{customName1}</b> {t('REPORT_REVIEW_RETURN')} <b>{customName2}</b>
		// 			.
		// 		</div>
		// 	);
		// case NOTIFICATION_TYPES.REPORT_APPROVE_RETURN_NOTIFICATION:
		// 	return (
		// 		<div>
		// 			{t('THE')} <b>{customName1}</b> {t('REPORT_APPROVE_RETURN')}{' '}
		// 			<b>{customName2}</b>.
		// 		</div>
		// 	);
		case NOTIFICATION_TYPES.REPORT_RETURN_NOTIFICATION:
			return (
				<div>
					{t('THE')} <b>{customName1}</b> {t('REPORT_REVIEW_RETURN')} <b>{customName2}</b>
					.
				</div>
			);
		case NOTIFICATION_TYPES.PASSWORD_NOTIFICATION:
			return (
				<div>
					{t('PASSWORD_EXPIRE_IN')} <b>{customName1 === 0 ? t('TODAY') : customName1}</b>{' '}
					{t('PASSWORD_EXPIRE_TEXT')}
				</div>
			);

		// WeblinkID
		case NOTIFICATION_TYPES.WEBLINKID_ACTIVATION_NOTIFICATION:
			return (
				<div>
					{t('ACTIVATION_INITIATED_FOR_THE_WEBLINK')} <b>{customName1}</b>{' '}
					{t('AND_WAITING_FOR_REVIEW')}
				</div>
			);
		case NOTIFICATION_TYPES.WEBLINKID_ACTIVATION_REVIEW_NOTIFICATION:
			return (
				<div>
					{t('THE_REACTIVATION_OF')} <b>{customName1}</b>{' '}
					{t('HAS_BEEN_REVIEWED_SUCCESSFULLY_BY_THE_REVIEWER_FOR_THE_PRODUCT')}{' '}
					<b>{customName2}</b>
				</div>
			);
		case NOTIFICATION_TYPES.WEBLINKID_ACTIVATION_APPROVAL_NOTIFICATION:
			return (
				<div>
					{t('THE_REACTIVATION_OF')} <b>{customName1}</b>{' '}
					{t('HAS_BEEN_APPROVED_SUCCESSFULLY_BY_THE_APPROVER_FOR_THE_PRODUCT')}{' '}
					<b>{customName2}</b>
				</div>
			);
		case NOTIFICATION_TYPES.WEBLINKID_DEACTIVATION_NOTIFICATION:
			return (
				<div>
					{t('DEACTIVATION_INITIATED_FOR_THE_WEBLINK')} <b>{customName1}</b>{' '}
					{t('AND_WAITING_FOR_APPROVE')}
				</div>
			);

		case NOTIFICATION_TYPES.WEBLINKID_DEACTIVATION_REVIEW_NOTIFICATION:
			return (
				<div>
					{t('THE_DEACTIVATION_OF')} <b>{customName1}</b>{' '}
					{t('HAS_BEEN_REVIEWED_SUCCESSFULLY_BY_THE_REVIEWER_FOR_THE_PRODUCT')}{' '}
					<b>{customName2}</b>
				</div>
			);
		case NOTIFICATION_TYPES.WEBLINKID_DEACTIVATION_APPROVAL_NOTIFICATION:
			return (
				<div>
					{t('THE_DEACTIVATION_OF')} <b>{customName1}</b>{' '}
					{t('HAS_BEEN_APPROVED_SUCCESSFULLY_BY_THE_APPROVER_FOR_THE_PRODUCT')}{' '}
					<b>{customName2}</b>
				</div>
			);
		case NOTIFICATION_TYPES.WEBLINKID_DEACTIVATION_REVIEW_REJECT_NOTIFICATION:
			return (
				<div>
					{t('THE_DEACTIVATION_REVIEW_OF')} <b>{customName1}</b> {t('FOR_THE_PRODUCT')}{' '}
					<b>{customName2}</b> {t('REJECTED_BY_REVIEWER')} {t('REMARKS')} {remarks}
				</div>
			);

		case NOTIFICATION_TYPES.WEBLINKID_DEACTIVATION_APPROVAL_REJECT_NOTIFICATION:
			return (
				<div>
					{t('THE_DEACTIVATION_APPROVE_OF')} <b>{customName1}</b> {t('FOR_THE_PRODUCT')}{' '}
					<b>{customName2}</b> {t('REJECTED_BY_APPROVER')} {t('REMARKS')} {remarks}
				</div>
			);
		case NOTIFICATION_TYPES.WEBLINKID_ACTIVATION_REVIEW_REJECT_NOTIFICATION:
			return (
				<div>
					{t('THE_REACTIVATION_REVIEW_OF')} <b>{customName1}</b> {t('FOR_THE_PRODUCT')}{' '}
					<b>{customName2}</b>
					{t('REJECTED_BY_REVIEWER')} {t('REMARKS')} {remarks}
				</div>
			);
		case NOTIFICATION_TYPES.WEBLINKID_ACTIVATION_APPROVAL_REJECT_NOTIFICATION:
			return (
				<div>
					{t('THE_REACTIVATION_APPROVE_OF')} <b>{customName1}</b> {t('FOR_THE_PRODUCT')}{' '}
					<b>{customName2}</b>
					{t('REJECTED_BY_APPROVER')} {t('REMARKS')} {remarks}
				</div>
			);

		case NOTIFICATION_TYPES.WEBLINKID_ACTIVATION_REVIEW_NOTIFICATION_ACTION:
			return (
				<div>
					{t('REACTIVATION_IS_INITIATED_FOR_THE_WEBLINK')} <b>{customName1}</b>{' '}
					{t('OF_PRODUCT')} <b>{customName2}</b> {t('AND_WAITING_FOR_REVIEW')}
				</div>
			);

		case NOTIFICATION_TYPES.WEBLINKID_DEACTIVATION_REVIEW_NOTIFICATION_ACTION:
			return (
				<div>
					{t('DEACTIVATION_IS_INITIATED_FOR_THE_WEBLINK')} <b>{customName1}</b>{' '}
					{t('OF_PRODUCT')} <b>{customName2}</b> {t('AND_WAITING_FOR_REVIEW')}
				</div>
			);
		case NOTIFICATION_TYPES.WEBLINKID_ACTIVATION_APPROVAL_NOTIFICATION_ACTION:
			return (
				<div>
					{t('REACTIVATION_IS_INITIATED_FOR_THE_WEBLINK')} <b>{customName1}</b>{' '}
					{t('OF_PRODUCT')} <b>{customName2}</b> {t('AND_WAITING_FOR_APPROVE')}
				</div>
			);
		case NOTIFICATION_TYPES.WEBLINKID_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION:
			return (
				<div>
					{t('DEACTIVATION_IS_INITIATED_FOR_THE_WEBLINK')} <b>{customName1}</b>{' '}
					{t('OF_PRODUCT')} <b>{customName2}</b> {t('AND_WAITING_FOR_APPROVE')}
				</div>
			);
		case NOTIFICATION_TYPES.EPCIS_FILE_SUCCESS:
			return (
				<div>
					{t('EPCIS_FILE')} <b>{customName1}</b> {t('SENT_THROUGH')} <b>{customName2}</b>{' '}
					{t('SUCCESFULLY_RECIEVED')} <b>{customName3}</b> {t('AT')}
					{utcTime(createdOn, enable24Hr)} {t('FROM_LOCATION')}
					<b>{customName4}</b>.
				</div>
			);
		case NOTIFICATION_TYPES.EPCIS_FILE_FAILED:
			return (
				<div>
					{t('EPCIS_FILE')} <b>{customName1}</b> {t('SENT_THROUGH')} <b> {customName2}</b>{' '}
					{t('FAILED_RECEIVED')} <b>{customName3}</b> {t('AT')}
					{utcTime(createdOn, enable24Hr)} {t('FROM_LOCATION')}
					<b>{customName4}</b>.
				</div>
			);

		case NOTIFICATION_TYPES.PRODUCT_FILE_COMPLETELY_FAILED_NOTIFICATION:
			return (
				<div>
					{t('THE_IMPORTING_PRODUCT_FILE')} <b>{customName1}</b>{' '}
					{t('HAS_BEEN_COMPLETELY_FAILED_AT')} {utcTime(createdOn, enable24Hr)}
				</div>
			);
		case NOTIFICATION_TYPES.PRODUCT_FILE_PARTIALLY_FAILED_NOTIFICATION:
			return (
				<div>
					{t('THE_IMPORTING_PRODUCT_FILE')} <b>{customName1}</b>{' '}
					{t('HAS_BEEN_PARTIALLY_FAILED_AT')} {utcTime(createdOn, enable24Hr)}
				</div>
			);
		case NOTIFICATION_TYPES.PRODUCT_FILE_IMPORT_FAILED_NOTIFICATION:
			return (
				<div>
					{t('THE_IMPORTING_PRODUCT_FILE')} <b>{customName1}</b> {t('HAS_FAILED_AT')}{' '}
					{utcTime(createdOn, enable24Hr)} {t('DUE_TO_INVALID_FILE_FORMAT')}
				</div>
			);

		/** Notification text of weblink retrival */
		case NOTIFICATION_TYPES.WEBLINK_FILE_IMPORT_FAILED_NOTIFICATION:
			return (
				<div>
					{t('THE_IMPORTING_OF_WEBLINK_STATUS_FILE')} <b>{customName1}</b>{' '}
					{t('HAS_BEEN_FAILED_AT')} {utcTime(createdOn, enable24Hr)}{' '}
					{t('DUE_TO_INVALID_FILE_FORMAT')}
				</div>
			);
		case NOTIFICATION_TYPES.WEBLINK_FILE_PARTIALLY_FAILED_NOTIFICATION:
			return (
				<div>
					{t('THE_IMPORTING_OF_WEBLINK_STATUS_FILE')} <b>{customName1}</b>{' '}
					{t('HAS_BEEN_PARTIALLY_FAILED')} {utcTime(createdOn, enable24Hr)}
				</div>
			);
		case NOTIFICATION_TYPES.WEBLINK_FILE_COMPLETELY_FAILED_NOTIFICATION:
			return (
				<div>
					{t('THE_IMPORTING_OF_WEBLINK_STATUS_FILE')} <b>{customName1}</b>{' '}
					{t('HAS_BEEN_COMPLETELY_FAILED')} {utcTime(createdOn, enable24Hr)}
				</div>
			);
		default:
			return <>{t('UNWANTED_MESSAGE')}</>;
	}
};
export default NotificationTextCreation;
