import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/stateHooks';

import { hasPrivileges, productFormValidations } from 'utils/utils';
import { ICON_DESCRIPTION_AREA_ID, MODULE_IDS, Privileges, RouteUrl } from 'utils/enum';
import { getUserLoginData } from 'slices/userLoginDataSlice';
import {
	getModulesRequireAuth,
	// getPasswordCapital,
	// getPasswordMaxChar,
	// getPasswordMinChar,
	// getPasswordNumeric,
	// getPasswordSpecialChar,
} from 'slices/configSlice';
import { setL4SystemIconsData } from 'slices/infoIconsDataSlice';
import {
	getAddress,
	getConnectionMethodHost,
	getConnectionMethodPassword,
	getConnectionMethodPortno,
	getConnectionMethodTenantid,
	getConnectionMethodUsername,
	getCountry,
	getDescription,
	getL4Location,
	getL4SystemName,
	getSelectedConnectionType,
	setAddress,
	setConnectionMethodHost,
	setConnectionMethodPassword,
	setConnectionMethodPortno,
	setConnectionMethodTenantid,
	setConnectionMethodUsername,
	setCountry,
	setCreateL4SubmitButtonStatus,
	setDescription,
	setL4Location,
	setL4SystemName,
	setSelectedConnectionType,
	setSftpPasswordErrorMsg,
	setUpdateL4SubmitButtonStatus,
} from 'slices/l4SystemSlice';

import L4Skeleton from 'components/common/skeleton/L4Skeleton';
import CustomLoader from 'components/common/loader/CustomLoader';
import PageBackButton from 'components/common/pageBackButton/PageBackButton';
import PasswordVerification from 'components/login/PasswordVerification';
import CustomButton from 'components/common/button/CustomButton';
import useGetIconDescription from 'components/common/tooltip/hooks/useGetIconDescription';
import * as constants from '../../utils/constants';
import CreateL4Config from './CreateL4Config';
import ConnectionMethod from './ConnectionMethod';
import useGetL4SystemById from './hooks/useGetL4SystemById';
import useAddL4System from './hooks/useAddL4System';
import useUpdateL4System from './hooks/useUpdateL4System';

const AddEditL4System = () => {
	const { t } = useTranslation('l4System');
	const navigate = useNavigate();

	const location = useLocation();

	const [isPasswordVerification, setIsPasswordVerification] = useState<boolean>(false);
	const modulesRequireAuth = useAppSelector(getModulesRequireAuth);

	const getLoginUserData = useAppSelector(getUserLoginData);
	const dispatch = useAppDispatch();
	const selectedConnectionType = useAppSelector(getSelectedConnectionType);
	const l4SystemName = useAppSelector(getL4SystemName);
	const address = useAppSelector(getAddress);
	const description = useAppSelector(getDescription);
	const l4Location = useAppSelector(getL4Location);
	const country = useAppSelector(getCountry);
	const sftpHost = useAppSelector(getConnectionMethodHost);
	const sftpUsername = useAppSelector(getConnectionMethodUsername);
	const sftpPassword = useAppSelector(getConnectionMethodPassword);
	const sftpPortNo = useAppSelector(getConnectionMethodPortno);
	const restApiTenantId = useAppSelector(getConnectionMethodTenantid);

	const [errorState, setErrorState] = useState<Record<string, string>>({});
	const [l4ById, setL4ById] = useState<any>(0);

	// const configPasswordMinLength = useAppSelector(getPasswordMinChar);
	// const configPasswordMaxLength = useAppSelector(getPasswordMaxChar);
	// const configPasswordNumeric = useAppSelector(getPasswordNumeric);
	// const configPasswordOneCapital = useAppSelector(getPasswordCapital);
	// const configPasswordSpecialChar = useAppSelector(getPasswordSpecialChar);
	const getUserDetails = useAppSelector(getUserLoginData);

	const [buttonDisable, setButtonDisable] = useState<boolean>(false);

	const { resData, l4Loading } = useGetL4SystemById(l4ById);

	const { addpL4Response, loading } = useAddL4System({
		l4SystemName: l4SystemName?.trim(),
		address: address?.trim(),
		description: description?.trim(),
		country,
		sftpHost: sftpHost?.trim(),
		sftpUsername: sftpUsername?.trim(),
		sftpPassword: sftpPassword?.trim(),
		sftpPortNo,

		restApiTenantId: restApiTenantId?.trim(),
		selectedConnectionType,
		l4Location: l4Location?.trim(),
	});

	const { updateL4Response, updateL4loading } = useUpdateL4System({
		encSystemID: l4ById,
		l4SystemName: l4SystemName?.trim(),
		address: address?.trim(),
		encCountryId: country?.encCountryId,
		description: description?.trim(),
		selectedConnectionType,
		country,
		sftpHost: sftpHost?.trim(),
		sftpUsername: sftpUsername?.trim(),
		sftpPassword: sftpPassword?.trim(),
		sftpPortNo,

		restApiTenantId: restApiTenantId?.trim(),
		l4Location: l4Location?.trim(),
	});

	const { iconDescriptionResponse } = useGetIconDescription(ICON_DESCRIPTION_AREA_ID.L4_SYSTEM);

	// sets icon description data in state
	useEffect(() => {
		if (iconDescriptionResponse) {
			dispatch(setL4SystemIconsData(iconDescriptionResponse?.output));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [iconDescriptionResponse]);

	const onSaveButtonClick = () => {
		const error = productFormValidations(
			{
				l4SystemName,
				address,
				description,
				country,
				sftpHost,
				sftpUsername,
				sftpPassword,
				sftpPortNo,
				restApiTenantId,
			},
			{
				l4SystemName: {
					required: true,
					maxlength: 150,
				},
				address: {
					required: true,
					maxlength: 250,
				},
				description: {
					required: true,
					maxlength: 250,
				},
				country: {
					required: true,
				},
				sftpHost: {
					required: selectedConnectionType === constants.SFTP,
					maxlength: 150,
				},
				sftpUsername: {
					required: true,
					// maxlength: 150,
					// alphanumericWithSpace: true,
				},
				sftpPassword: {
					required: true,
				},

				restApiTenantId: {
					required: selectedConnectionType === constants.REST_API,
				},
				sftpPortNo: {
					required: selectedConnectionType === constants.SFTP,
				},
			},
		);
		// const sftpPasswordError = passwordValidation(sftpPassword, {
		// 	passwordMinLength: configPasswordMinLength,
		// 	passwordMaxLength: configPasswordMaxLength,
		// 	passwordNumeric: configPasswordNumeric,
		// 	passwordCapital: configPasswordOneCapital,
		// 	passwordSpecialChar: configPasswordSpecialChar,
		// });
		// if (sftpPasswordError !== '') {
		// 	dispatch(setSftpPasswordErrorMsg(sftpPasswordError));
		// }

		setErrorState(error);
		// setIsSubmitted(true);
		if (l4ById !== 0) {
			if (
				Object.keys(error).length === 0
				// && sftpPasswordError === ''
			) {
				if (modulesRequireAuth.includes(MODULE_IDS.L4_MANAGEMENT)) {
					setIsPasswordVerification(true);
				} else {
					dispatch(setUpdateL4SubmitButtonStatus(true));
				}
			}
		} else if (
			Object.keys(error).length === 0
			// && sftpPasswordError === ''
		) {
			if (modulesRequireAuth.includes(MODULE_IDS.L4_MANAGEMENT)) {
				setIsPasswordVerification(true);
			} else {
				dispatch(setCreateL4SubmitButtonStatus(true));
			}
		}
	};

	useEffect(() => {
		if (l4SystemName !== '') {
			setErrorState((prev) => ({ ...prev, l4SystemName: '' }));
		}
		if (address !== '') {
			setErrorState((prev) => ({ ...prev, address: '' }));
		}
		if (description !== '') {
			setErrorState((prev) => ({ ...prev, description: '' }));
		}
		if (country !== '') {
			setErrorState((prev) => ({ ...prev, country: '' }));
		}
		if (sftpHost !== '') {
			setErrorState((prev) => ({ ...prev, sftpHost: '' }));
		}
		if (sftpUsername !== '') {
			setErrorState((prev) => ({ ...prev, sftpUsername: '' }));
		}
		if (sftpPassword !== '') {
			setErrorState((prev) => ({ ...prev, sftpPassword: '' }));
		}

		if (restApiTenantId !== '') {
			setErrorState((prev) => ({ ...prev, restApiTenantId: '' }));
		}

		if (sftpPortNo !== '') {
			setErrorState((prev) => ({ ...prev, sftpPortNo: '' }));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		l4SystemName,
		address,
		description,
		country,
		sftpHost,
		sftpUsername,
		sftpPassword,
		restApiTenantId,

		sftpPortNo,
	]);

	// to clear sftp  password err msgs when changed page
	useEffect(() => {
		return () => {
			dispatch(setSftpPasswordErrorMsg(''));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// to clear connection method err msg when method changed
	const clearConnectionMethodErrMsgs = () => {
		setErrorState((prev) => ({ ...prev, sftpHost: '' }));
		setErrorState((prev) => ({ ...prev, sftpUsername: '' }));

		dispatch(setSftpPasswordErrorMsg(''));
		setErrorState((prev) => ({ ...prev, sftpPortNo: '' }));
	};

	// to show the validation msg of country when selected please select option
	useEffect(() => {
		if (country?.encCountryId === '0') {
			dispatch(setCountry(''));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [country]);

	useEffect(() => {
		setL4ById(location.state && location.state?.id);
	}, [location.state]);

	const clearAllFields = () => {
		dispatch(setL4SystemName(''));
		dispatch(setAddress(''));
		dispatch(setDescription(''));

		dispatch(setCountry(''));
		dispatch(setL4Location(''));
		dispatch(setConnectionMethodHost(''));
		dispatch(setConnectionMethodUsername(''));
		dispatch(setConnectionMethodPassword(''));
		dispatch(setConnectionMethodPortno(''));
		dispatch(setConnectionMethodTenantid(''));
	};

	useEffect(() => {
		if (resData && resData.length > 0) {
			dispatch(setL4SystemName(resData[0]?.l4SystemName));
			dispatch(setAddress(resData[0]?.address));
			dispatch(setDescription(resData[0]?.description));

			dispatch(setCountry(resData[0]?.encCountryId));
			dispatch(setL4Location(resData[0]?.location));
			dispatch(setSelectedConnectionType(resData[0]?.connectionMethodID));
			dispatch(setConnectionMethodHost(resData[0]?.connectionDetails?.host));
			dispatch(setConnectionMethodUsername(resData[0]?.connectionDetails?.userName));
			dispatch(setConnectionMethodPassword(resData[0]?.connectionDetails?.password));
			dispatch(setConnectionMethodPortno(resData[0]?.connectionDetails?.portNo));
			dispatch(
				setConnectionMethodTenantid(
					resData[0].connectionDetails?.identifier !== ''
						? resData[0]?.connectionDetails?.identifier
						: getUserDetails?.tenantCode,
				),
			);
		}

		return () => {
			clearAllFields();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [l4ById, resData]);

	useEffect(() => {
		if (addpL4Response) {
			if (addpL4Response?.status) {
				dispatch(setCreateL4SubmitButtonStatus(false));
				navigate(RouteUrl.L4SYSTEM);
			} else {
				dispatch(setCreateL4SubmitButtonStatus(false));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addpL4Response]);

	useEffect(() => {
		if (updateL4Response) {
			if (updateL4Response?.status) {
				dispatch(setUpdateL4SubmitButtonStatus(false));

				navigate(RouteUrl.L4SYSTEM);
			} else {
				dispatch(setUpdateL4SubmitButtonStatus(false));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateL4Response]);

	const handleToggle = () => {
		setIsPasswordVerification(false);
	};

	/**
	 * fuction for handle verification success
	 */
	const handleVerificationSuccess = () => {
		handleToggle();
		if (l4ById !== 0) {
			dispatch(setUpdateL4SubmitButtonStatus(true));
		} else {
			dispatch(setCreateL4SubmitButtonStatus(true));
		}
	};

	const getButtonDisable = (disable: boolean) => {
		setButtonDisable(disable);
	};

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			<div className='page-content'>
				<div className='page-header back-btn-header'>
					<div className='back-btn-header'>
						<PageBackButton url={RouteUrl.L4SYSTEM} />
						<h3>{t('L4_SYSTEM_CREATION')}</h3>
					</div>
				</div>

				<div className='page-content-body common-page-content-body'>
					<div className='box-container create-l3-box-container'>
						{l4Loading ? (
							<L4Skeleton />
						) : (
							<div className='default-form-style'>
								<div className='row'>
									<div className='col-lg-6 col-md-6'>
										<CreateL4Config errorState={errorState} l4ById={l4ById} />
									</div>
									<div className='col-lg-6 col-md-6'>
										<ConnectionMethod
											errorState={errorState}
											clearErrMessage={clearConnectionMethodErrMsgs}
											getButtonDisable={getButtonDisable}
										/>
									</div>
								</div>
							</div>
						)}
						<div className='btn-footer-wrapper'>
							{hasPrivileges(Privileges.CreateL4System, getLoginUserData) && (
								<CustomButton
									disabled={buttonDisable}
									buttonClassName='btn btn-primary h-44px icon-add-btn'
									buttonName={
										loading || updateL4loading ? (
											<CustomLoader />
										) : (
											t(l4ById !== 0 ? `${t('UPDATE')}` : `${t('CREATE')}`)
										)
									}
									handleClick={onSaveButtonClick}
									type='button'
								/>
							)}
						</div>
					</div>
				</div>
			</div>

			{/* Password verification component */}
			{isPasswordVerification && modulesRequireAuth.includes(MODULE_IDS.L4_MANAGEMENT) && (
				<PasswordVerification
					onVerificationSuccess={handleVerificationSuccess}
					toggleDialog={handleToggle}
				/>
			)}
		</>
	);
};

export default AddEditL4System;
