import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'hooks/stateHooks';
import { getL4SystemIconsData } from 'slices/infoIconsDataSlice';
import { getUserLoginData } from 'slices/userLoginDataSlice';
import CustomInput from 'components/common/forms/CustomInput';
import CustomEditableTooltip from 'components/common/tooltip/CustomEditableTooltip';
import { ICON_DESCRIPTION_ID } from 'utils/enum';
import {
	getConnectionMethodHost,
	getConnectionMethodPassword,
	getConnectionMethodPortno,
	getConnectionMethodTenantid,
	getConnectionMethodUsername,
	getSelectedConnectionType,
	setConnectionMethodHost,
	setConnectionMethodPassword,
	setConnectionMethodPortno,
	setConnectionMethodTenantid,
	setConnectionMethodUsername,
	setSelectedConnectionType,
	setSftpPasswordErrorMsg,
} from 'slices/l4SystemSlice';

import ConnectionMethodSkeleton from 'components/common/skeleton/ConnectionMethodSkeleton';
import CustomRadioButton from 'components/common/forms/CustomRadioButton';
import ShowIcon from 'components/common/fontAwesome/ShowIcon';
import * as constants from '../../utils/constants';
import useGetAllConnections from './hooks/useGetAllConnectionMethods';

const ConnectionMethod = ({ errorState, clearErrMessage, getButtonDisable }: any) => {
	const { t } = useTranslation('l4System');
	const dispatch = useAppDispatch();
	// const [getAllConnections, setGetAllConnections] = useState<any>([]);
	const selectedConnectionType = useAppSelector(getSelectedConnectionType);
	const host = useAppSelector(getConnectionMethodHost);
	const username = useAppSelector(getConnectionMethodUsername);
	const password = useAppSelector(getConnectionMethodPassword);
	const portNo = useAppSelector(getConnectionMethodPortno);
	const tenantId = useAppSelector(getConnectionMethodTenantid);

	const { connectionList, connectionListLoading } = useGetAllConnections();
	const getUserDetails = useAppSelector(getUserLoginData);

	const l4SystemIconsData = useAppSelector(getL4SystemIconsData);
	const [connectionMethodInfo, setConnectionMethodInfo] = useState<string>('');

	const [connectionMethodPasswordShown, setConnectionMethodPasswordShown] =
		useState<boolean>(false);

	// finds 4 connection method info from redux state
	useEffect(() => {
		// eslint-disable-next-line no-unused-expressions
		l4SystemIconsData.length > 0 &&
			// eslint-disable-next-line array-callback-return
			l4SystemIconsData?.map((item: any) => {
				if (item?.encIconId === ICON_DESCRIPTION_ID.L4_CONNECTION) {
					setConnectionMethodInfo(item?.iconDescription);
				}
			});
	}, [l4SystemIconsData]);

	useEffect(() => {
		if (getUserDetails) {
			dispatch(setConnectionMethodTenantid(getUserDetails?.tenantCode));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getUserDetails?.tenantCode]);

	useEffect(() => {
		getButtonDisable(connectionListLoading);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [connectionListLoading]);

	// to clear connection method err msg when method changed
	const clearErrMsgs = () => {
		clearErrMessage();
	};

	const onConnectionMethodChange = (e: any) => {
		dispatch(setSelectedConnectionType(e));
		dispatch(setConnectionMethodUsername(''));
		dispatch(setConnectionMethodPassword(''));
		dispatch(setConnectionMethodHost(''));
		dispatch(setConnectionMethodPortno(''));
		clearErrMsgs();
	};

	const onHostChange = (e: any) => {
		dispatch(setConnectionMethodHost(e.target.value));
	};

	const onUsernameChange = (e: any) => {
		dispatch(setConnectionMethodUsername(e.target.value));
	};

	const onPasswordChange = (e: any) => {
		dispatch(setConnectionMethodPassword(e.target.value));

		dispatch(setSftpPasswordErrorMsg(''));
	};

	const onPortnoChange = (e: any) => {
		dispatch(setConnectionMethodPortno(Number(e.target.value)));
	};

	const onTenantIdChange = (e: any) => {
		dispatch(setConnectionMethodTenantid(e.target.value));
	};

	const toggleConnectionMethodPasswordVisiblity = () => {
		setConnectionMethodPasswordShown((prevState) => !prevState);
	};

	return (
		<div className='section-wrapper'>
			<h4 className='form-section-title mb-3 info-title'>
				{t('CONNECTION_METHOD_HEADING')}
				<CustomEditableTooltip
					showIcon='faInfoCircle'
					text={connectionMethodInfo}
					moduleId={ICON_DESCRIPTION_ID.L4_CONNECTION}
				/>
			</h4>
			{connectionListLoading ? (
				<ConnectionMethodSkeleton />
			) : (
				<>
					{' '}
					<div className='row connection-method-row'>
						{connectionList &&
							connectionList.length > 0 &&
							connectionList.map((data: any) => {
								return (
									<div
										className='col-lg-4 col-md-6 col-sm-4'
										key={`${data.connectionMethodID}div`}>
										<div className='form-group'>
											<CustomRadioButton
												className=''
												checked={
													selectedConnectionType ==
													data.connectionMethodID
												}
												handleChange={onConnectionMethodChange}
												id={data.connectionMethodID}
												value={data.connectionMethodID}
												label={data.connectionMethod}
											/>
										</div>
									</div>
								);
							})}
					</div>
					<div className='row'>
						{selectedConnectionType === constants.SFTP && (
							<div className='col-lg-6 col-md-12 col-sm-6'>
								<div className='form-group'>
									<CustomInput
										className=''
										value={host}
										label={t('HOST')!!}
										onChange={(e: any) => onHostChange(e)}
										name='host'
										placeholder={t('HOST')}
										required
										type='text'
										error={errorState.sftpHost}
										isMandatory
									/>
								</div>
							</div>
						)}

						{selectedConnectionType === constants.REST_API && (
							<div className='col-lg-6 col-md-12 col-sm-6'>
								<div className='form-group'>
									<CustomInput
										className=''
										value={tenantId}
										label={t('TENANT_ID')!!}
										onChange={(e: any) => onTenantIdChange(e)}
										name='host'
										placeholder={t('TENANT_ID')}
										required
										disabled
										type='text'
										isMandatory
										error={errorState.restApiTenantId}
									/>
								</div>
							</div>
						)}
						<div className='col-lg-6 col-md-12 col-sm-6'>
							<div className='form-group'>
								<CustomInput
									className=''
									value={username}
									label={t('USERNAME')!!}
									onChange={(e: any) => onUsernameChange(e)}
									name='username'
									placeholder={t('USERNAME')}
									required
									type='text'
									error={errorState.sftpUsername}
									isMandatory
								/>
							</div>
						</div>
						<div className='col-lg-6 col-md-12 col-sm-6'>
							<div className='form-group password-form-group'>
								<CustomInput
									className=''
									value={password}
									label={t('PASSWORD')!!}
									onChange={(e: any) => onPasswordChange(e)}
									name='password'
									placeholder={t('PASSWORD')}
									required
									type={connectionMethodPasswordShown ? 'text' : 'password'}
									error={errorState.sftpPassword}
									isMandatory
								/>
								<i
									className='show-password'
									role='button'
									onKeyDown={toggleConnectionMethodPasswordVisiblity}
									tabIndex={0}
									onClick={toggleConnectionMethodPasswordVisiblity}>
									{connectionMethodPasswordShown ? (
										<ShowIcon name='faEye' />
									) : (
										<ShowIcon name='faEyeSlash' />
									)}
								</i>
							</div>
						</div>
						{selectedConnectionType === constants.SFTP && (
							<div className='col-lg-6 col-md-12 col-sm-6'>
								<div className='form-group input-type-number type-two'>
									<CustomInput
										className=''
										value={portNo}
										label={t('PORT_NO')!!}
										onChange={(e: any) => onPortnoChange(e)}
										name='portNo'
										placeholder={t('PORT_NO')}
										error={errorState.sftpPortNo}
										required
										type='number'
										isMandatory
									/>
								</div>
							</div>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default ConnectionMethod;
