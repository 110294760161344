import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

// import LoginLogo from 'assets/images/ALSC-Logo-loginpage.svg';

import ProductImageCarousel from 'components/endUserScan/ProductImageCarousel';
import ScannedProductDetails from 'components/endUserScan/ScannedProductDetails';
import useGetEndUserScannedData from 'components/endUserScan/hooks/useGetEndUserScannedData';
import EndUserSkeleton from 'components/common/skeleton/EndUserSkeleton';
import { useAppSelector } from 'hooks/stateHooks';
import { getDataFormat, getEnable24Hr } from 'slices/configSlice';
import { utcFormat, utcTime } from 'utils/utils';
import useGetScanHistory from 'components/endUserScan/hooks/useGetScanHistory';
import { IEndUserProductDetailsType } from 'components/common/model';
import useGetConfiguredScanAttributes from './hooks/useGetConfiguredScanAttributes';

const Index = () => {
	const { t } = useTranslation('endUserScan');

	const getCurrentDate = useAppSelector(getDataFormat);
	const enable24Hr = useAppSelector(getEnable24Hr);

	const productDetailsInitial = {
		productStatus: '',
		productName: '',
		productDescription: '',
		productCode: '',
		manufacturer: '',
		productGroup: '',
		quantity: '',
		manufacturingDate: '',
		expiryDate: '',
		productionDate: '',
		productCategory: '',
		productOrder: '',
		lotNo: '',
		orderLocation: '',
		scanHistoryCount: 0,
		brandName: '',
		licenseNumber: '',
		isEndUserScanEnabled: false,
		dateFormat: getCurrentDate,
		timeFormat: enable24Hr,
		parentCompanyName: '',
		manufacturingAndExpiryDateFormat: 'MMM.yyyy',
	};

	const weblinkUrl = window.location.href;

	const [currentWeblink, setCurrentWeblink] = useState<string>(weblinkUrl);

	const [resultId, setResultId] = useState<number | null>(null);
	const [encProductId, setEncProductId] = useState<string | null>(null);
	const [fileName, setFileName] = useState<string | null>(null);
	const [encWebLinkId, setEncWeblinkId] = useState<string | null>(null);
	const [tenantLogo, setTenantLogo] = useState<any>(null);
	const [tenantFavIcon, setTenantFavIcon] = useState<any>(null);
	const [tenantTitle, setTenantTitle] = useState<any>('');

	const [statusFieldShow, setStatusFieldShow] = useState<boolean>(false);
	const [footerShow, setFooterShow] = useState<boolean>(false);

	const [image, setImage] = useState<any>(null);
	const [productDetails, setProductDetails] =
		useState<IEndUserProductDetailsType>(productDetailsInitial);
	const [scanHistoryData, setScanHistoryData] = useState<any>([]);
	const [weblinkValidScanStatus, setWeblinkValidScanStatus] = useState<boolean>(false);
	const [encVersionId, setEncVersionId] = useState<string | null>(null);

	/**
	 * API call for get End User Scanned Data
	 */
	const { endUserScanDataRes, endUserScanLoading } = useGetEndUserScannedData({
		currentWeblink,
	});

	/**
	 * API call for get product group fields Data
	 */
	const { productGroupFieldsDataLoading, productGroupFieldsDataRes } =
		useGetConfiguredScanAttributes(
			{
				currentWeblink,
				endUserScanDataRes,
			},
			weblinkValidScanStatus,
		);

	/**
	 * API call for get Scan History Data
	 */
	const { scanHistoryDataRes, scanHistoryLoading } = useGetScanHistory(
		encWebLinkId,
		productDetails?.isEndUserScanEnabled,
	);

	useEffect(() => {
		setResultId(0);
	}, []);

	/**
	 * used to update the favicon and title
	 */
	useEffect(() => {
		const link: any = document.querySelector('link[rel="icon"]');
		if (tenantFavIcon) {
			link.setAttribute('href', tenantFavIcon);
		}
		if (tenantTitle) {
			document.title = tenantTitle;
		}

		// return () => {
		// 	link.setAttribute('href', 'data:image/png;base64,');
		// };
	}, [tenantFavIcon, tenantTitle]);

	useEffect(() => {
		setCurrentWeblink(weblinkUrl);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [weblinkUrl]);

	useEffect(() => {
		if (endUserScanDataRes) {
			// eslint-disable-next-line no-unused-expressions
			endUserScanDataRes?.encProductId && setEncProductId(endUserScanDataRes?.encProductId);
			// eslint-disable-next-line no-unused-expressions
			endUserScanDataRes?.imageBase64 && setImage(endUserScanDataRes?.imageBase64);
			// eslint-disable-next-line no-unused-expressions
			endUserScanDataRes?.fileName && setFileName(endUserScanDataRes?.fileName);
			// eslint-disable-next-line no-unused-expressions
			endUserScanDataRes?.tenantLogoBase64 &&
				setTenantLogo(endUserScanDataRes?.tenantLogoBase64);

			setStatusFieldShow(endUserScanDataRes?.endUserWeblinkValidShow);
			setFooterShow(endUserScanDataRes?.endUserFooterTextShow);

			setProductDetails((prev) => ({
				...prev,
				productStatus: endUserScanDataRes?.isActive,
				productName: endUserScanDataRes?.productName,
				productDescription: endUserScanDataRes?.productDescription,
				productCode: endUserScanDataRes?.productCode,
				manufacturer: endUserScanDataRes?.manufacturerName,
				productGroup: endUserScanDataRes?.productGroup,
				quantity: endUserScanDataRes?.productWeight,
				manufacturingDate: endUserScanDataRes?.manufacturingDate,
				expiryDate: endUserScanDataRes?.expiryDate,
				productionDate: endUserScanDataRes?.productionDate,
				productCategory: endUserScanDataRes?.orderCategory,
				productOrder: endUserScanDataRes?.productOrder,
				lotNo: endUserScanDataRes?.lotNumber,
				orderLocation: endUserScanDataRes?.orderLocation,
				brandName: endUserScanDataRes?.brandName,
				licenseNumber: endUserScanDataRes?.licenseNumber,
				genericName: endUserScanDataRes?.genericName,
				address: endUserScanDataRes?.address,
				isEndUserScanEnabled: endUserScanDataRes?.isEndUserScanEnabled,
				dateFormat: endUserScanDataRes?.dateTimeFormat,
				timeFormat: endUserScanDataRes?.isTwentyFourHrEnabled,
				parentCompanyName: endUserScanDataRes?.parentCompanyName,
				manufacturingAndExpiryDateFormat:
					endUserScanDataRes?.manufacturingAndExpiryDateFormat || 'MMM.yyyy',
				hideTenantPrefix: endUserScanDataRes?.hideTenantPrefix,
				customWeblinkStatus: endUserScanDataRes?.customWeblinkStatus,
				marketedBy: endUserScanDataRes?.marketedBy,
				productNameSuperscript: endUserScanDataRes?.productNameSuperscript,
				serialNumber: endUserScanDataRes?.serialNumber,
				isSerialNumberEnabled: endUserScanDataRes?.isSerialNumberEnabled,
				excipents: endUserScanDataRes?.excipents,
			}));

			// eslint-disable-next-line no-unused-expressions
			endUserScanDataRes?.resultId && setResultId(endUserScanDataRes?.resultId);
			// eslint-disable-next-line no-unused-expressions
			endUserScanDataRes?.encWebLinkID && setEncWeblinkId(endUserScanDataRes?.encWebLinkID);

			// set favicon
			// if (endUserScanDataRes?.favIconBase64) {
			setTenantFavIcon(
				endUserScanDataRes?.favIconBase64 && endUserScanDataRes?.favIconBase64,
			);
			// } else {
			// 	const link: any = document.querySelector('link[rel="icon"]');
			// 	link.setAttribute('href', `${process.env.PUBLIC_URL}` + '/ALSC-Logo-menu.svg');
			// }

			// set title

			// if (endUserScanDataRes?.tenantTitle) {
			setTenantTitle(endUserScanDataRes?.tenantTitle && endUserScanDataRes?.tenantTitle);
			// } else {
			// 	document.title = 'ACG';
			// }
			// eslint-disable-next-line no-unused-expressions
			endUserScanDataRes?.encVersionId && setEncVersionId(endUserScanDataRes?.encVersionId);

			const endUserScanDataResLength = Object?.keys(endUserScanDataRes)?.length;
			if (endUserScanDataResLength > 0) {
				// calling the api based on the another api response to avoid triggering both api call at the same time
				setWeblinkValidScanStatus(true);
			}
		} else {
			// setting the state as default
			setWeblinkValidScanStatus(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endUserScanDataRes]);

	/**
	 * setting scan history data in state
	 */
	useEffect(() => {
		if (scanHistoryDataRes) {
			setProductDetails((prev) => ({
				...prev,
				scanHistoryCount: scanHistoryDataRes?.scanCount,
			}));

			const scanHistory =
				scanHistoryDataRes?.scanHistory &&
				scanHistoryDataRes?.scanHistory.map((item: any) => {
					return {
						location: item?.location ? item?.location : t('LOCATION_NOT_AVAILABLE')!!,
						date: item.scanDate
							? utcFormat(
									item.scanDate,
									productDetails?.dateFormat
										? productDetails?.dateFormat
										: getCurrentDate,
							  )
							: t('DATE_NOT_AVAILABLE')!!,

						time: item.scanDate
							? utcTime(item.scanDate, productDetails?.timeFormat)
							: t('TIME_NOT_AVAILABLE')!!,
						latitude: item?.latitude === 0 ? '' : item?.latitude,
						longitude: item?.longitude === 0 ? '' : item?.longitude,
					};
				});
			// eslint-disable-next-line no-unused-expressions
			scanHistory && scanHistory?.length > 0 && setScanHistoryData(scanHistory);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scanHistoryDataRes]);

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{endUserScanLoading || productGroupFieldsDataLoading ? (
				<EndUserSkeleton />
			) : (
				<div
					className={`sc-container end-user-container ${footerShow ? 'with-footer' : ''}${
						!tenantLogo ? ' no-tenant-logo' : ''
					}`}>
					<div className='first-sc-container'>
						{tenantLogo && (
							<div className='logo-container scanned-products'>
								<img
									className='tenant-scan-logo'
									src={tenantLogo && tenantLogo}
									alt='Logo'
								/>
							</div>
						)}
						<div className='scanned-products-wrapper'>
							<ProductImageCarousel image={image} />
						</div>
					</div>
					<div className='scanner-products-right-wrapper'>
						<div className='custom-scroller'>
							<ScannedProductDetails
								productDetails={productDetails}
								scanHistoryData={scanHistoryData}
								resultId={resultId}
								encProductId={encProductId}
								scanHistoryLoading={scanHistoryLoading}
								fileName={fileName}
								statusFieldShow={statusFieldShow}
								productGroupFieldsDataRes={productGroupFieldsDataRes}
								// productGroupFieldsDataLoading={productGroupFieldsDataLoading}
								encVersionId={encVersionId}
							/>
						</div>
					</div>
					{footerShow && (
						<div className='endUser-footer'>
							<label>{t('FOOTER_TEXT')}</label>
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default Index;
