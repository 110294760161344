import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { GridColumn as Column } from '@progress/kendo-react-grid';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { columnProps, sortUpandDown, formateDate, timeFormat } from 'utils/utils';

import CustomEditableTooltip from 'components/common/tooltip/CustomEditableTooltip';
import { ICON_DESCRIPTION_AREA_ID, ICON_DESCRIPTION_ID } from 'utils/enum';
import { useTranslation } from 'react-i18next';

import { SMALLGRIDPAGESIZE } from 'utils/constants';
import { PageState } from 'components/common/model';
import useGetIconDescription from 'components/common/tooltip/hooks/useGetIconDescription';

import PageBackButton from 'components/common/pageBackButton/PageBackButton';
import { useAppSelector } from 'hooks/stateHooks';
import { getDataFormat, getEnable24Hr } from 'slices/configSlice';
import {
	gridCommonHeaderCellWithSortOption,
	gridCommonHeaderCellWithoutFilterOption,
	onCommonGridSearchWithFieldName,
} from 'components/common/grid/GridCommonFunctions';

import CommonGridComponent from 'components/common/grid/CommonGridComponent';
import useGetFileHistoryData from './hooks/useGetFileHistoryData';

const initialDataState: PageState = { skip: 0, take: SMALLGRIDPAGESIZE };
const initialFilter: CompositeFilterDescriptor = {
	logic: 'and',
	filters: [{ field: 'ProductName', operator: 'contains', value: '' }],
};

const ExportHistoryL4 = () => {
	const [t] = useTranslation('productModule');
	const location = useLocation();

	const getCurrentDate = useAppSelector(getDataFormat);
	const enable24Hr = useAppSelector(getEnable24Hr);

	const [filter, setFilter] = useState(initialFilter);
	const [sortData, setSortData] = useState<Array<SortDescriptor>>([
		{ field: 'productName', dir: 'desc' },
	]);
	const [pagesizeValue, setPageSizeValue] = useState<number | string | undefined>();
	const [page, setPage] = useState<PageState>(initialDataState);
	const [sortDirection, setSortDirection] = useState<boolean>(true);

	const [fileHistoryData, setFileHistoryData] = useState<any>([]);
	const [fileHistoryDataCopy, setFileHistoryDataCopy] = useState<any>([]);

	const [searchValue, setSearchValue] = useState<any>();

	/** state for grid refresh and reset */
	const [refreshGrid, setRefreshGrid] = useState<boolean>(false);

	const [exportHistoryInfo, setExportHistoryInfo] = useState<string>('');

	const { fileHistoryResponseData, fileHistoryLoading } = useGetFileHistoryData(
		location?.state?.encLotNoId,
		refreshGrid,
	);

	/**
	 * Performs a grid search based on the provided event and  data.
	 *
	 * @param {any} event - The event triggering the search.
	 * @param {any} actionReportData - The data used for the grid search.
	 * @returns {void}
	 */
	const performGridSearch = (event: any, data: any) => {
		onCommonGridSearchWithFieldName(
			event,
			setSearchValue,
			data,
			setFileHistoryData,
			page,
			setPage,
			SMALLGRIDPAGESIZE,
			['slNo', 'l4SystemName', 'location', 'date', 'time'],
		);
	};

	useEffect(() => {
		if (fileHistoryResponseData && fileHistoryResponseData.length > 0) {
			const tempData = fileHistoryResponseData.map(
				(item: any, index: number) => ({
					...item,
					slNo: index + 1,
					date: formateDate(item?.date, getCurrentDate),
					time: timeFormat(item.scanDate, enable24Hr),
					// stateString: item.isActive ? 'Active' : 'inactive',
				}),
				[],
			);

			// for getting searched data after refresh
			if (searchValue && tempData) {
				// Apply search filtering if a search value exists
				performGridSearch({ value: searchValue }, tempData);
			}
			// If no search value, set the full dataset
			else {
				setFileHistoryData(tempData);
			}

			setFileHistoryDataCopy(tempData);
		}
		setRefreshGrid(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fileHistoryResponseData]);

	// hook to update info icon description
	const { iconDescriptionResponse } = useGetIconDescription(
		ICON_DESCRIPTION_AREA_ID.EXPORT_HISTORY,
		ICON_DESCRIPTION_ID.EXPORT_HISTORY,
	);

	// sets icon description data in state
	useEffect(() => {
		if (iconDescriptionResponse) {
			// eslint-disable-next-line no-unused-expressions
			iconDescriptionResponse?.output &&
				setExportHistoryInfo(iconDescriptionResponse?.output[0]?.iconDescription);
		}
	}, [iconDescriptionResponse]);

	// // filtering function based on redux state
	// useEffect(() => {
	// 	customFilteringFunction(filter, locationStatusFilterValue, 'isActive', setFilter);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [locationStatusFilterValue]);

	const handleSort = (field: any, dir: any) => {
		const dt = sortData;
		dt[0].field = field;
		dt[0].dir = dir ? 'asc' : 'desc';

		setSortDirection(!dir);

		setSortData(dt);
	};
	useEffect(() => {
		if (searchValue === '') {
			setFileHistoryData(fileHistoryDataCopy);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue]);

	useEffect(() => {
		sortUpandDown(sortData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortDirection]);

	/** to clear all filters applied on grid when reset button is clicked and to reload the data */
	const clearAllFilters = () => {
		setFilter(initialFilter);
		setSortData([{ field: 'slNo', dir: 'asc' }]);
		setPage(initialDataState);
		setSearchValue('');
		setRefreshGrid(true);
	};

	return (
		<div className='page-content user-module'>
			<div className='page-header'>
				<div className='back-btn-header'>
					<PageBackButton url={-1} />
					<h3>
						{t('EXPORT_HISTORY')}
						<CustomEditableTooltip
							showIcon='faInfoCircle'
							text={exportHistoryInfo}
							moduleId={ICON_DESCRIPTION_ID.EXPORT_HISTORY}
						/>
					</h3>
				</div>
			</div>
			<div className='page-content-body'>
				<div className='box-container userlist-box-container l3-box-container grid-custom-container'>
					<CommonGridComponent
						mainGridData={fileHistoryData}
						mainGridDataCopy={fileHistoryDataCopy}
						gridLoadingState={fileHistoryLoading}
						setRefreshGrid={setRefreshGrid}
						filter={filter}
						setFilter={setFilter}
						page={page}
						setPage={setPage}
						pagesizeValue={pagesizeValue}
						setPageSizeValue={setPageSizeValue}
						sortData={sortData}
						setSortData={setSortData}
						searchValue={searchValue}
						clearAllFilters={clearAllFilters}
						performGridSearch={performGridSearch}
						classname='grid-custom-container-inner with-toolbar'>
						<Column
							field='slNo'
							title={t('SLNO')!!}
							width='110px'
							headerCell={(props: any) =>
								gridCommonHeaderCellWithoutFilterOption(
									props,
									handleSort,
									sortDirection,
									sortData,
								)
							}
							sortable
						/>
						<Column
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...columnProps('l4SystemName', filter)}
							headerCell={(props: any) =>
								gridCommonHeaderCellWithSortOption(
									props,
									handleSort,
									sortDirection,
									sortData,
								)
							}
							title={t('L4_SYSTEM_NAME')!!}
							width='300px'
						/>
						<Column
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...columnProps('location', filter)}
							headerCell={(props: any) =>
								gridCommonHeaderCellWithSortOption(
									props,
									handleSort,
									sortDirection,
									sortData,
								)
							}
							title={t('LOCATION')!!}
							width='300px'
						/>
						<Column
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...columnProps('date', filter)}
							headerCell={(props: any) =>
								gridCommonHeaderCellWithSortOption(
									props,
									handleSort,
									sortDirection,
									sortData,
								)
							}
							title={t('DATE')!!}
							width='300px'
						/>
						<Column
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...columnProps('time', filter)}
							headerCell={(props: any) =>
								gridCommonHeaderCellWithSortOption(
									props,
									handleSort,
									sortDirection,
									sortData,
								)
							}
							title={t('TIME')!!}
							width='250px'
						/>
						<Column
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...columnProps('status', filter)}
							headerCell={(props: any) =>
								gridCommonHeaderCellWithSortOption(
									props,
									handleSort,
									sortDirection,
									sortData,
								)
							}
							title={t('STATUS')!!}
							width='250px'
						/>
					</CommonGridComponent>
				</div>
			</div>
		</div>
	);
};

export default ExportHistoryL4;
