import { ICustomSpan } from 'components/common/model';

const CustomSpan = ({ key, manufacturerLocationName }: ICustomSpan) => {
	return (
		<span className='chip-wrapper' key={key}>
			<span className='chip-lbl'>{manufacturerLocationName}</span>
		</span>
	);
};

export default CustomSpan;
