import { Suspense, lazy } from 'react';
import CommonSkeleton from 'components/common/skeleton/CommonSkeleton';

const UpdateWeblinkStatus = lazy(() => import('components/l3System/update-weblink/Index'));
const UpdateWeblinkStatusPage = () => {
	return (
		<div className='page-content user-module'>
			<Suspense fallback={<CommonSkeleton />}>
				<UpdateWeblinkStatus />
			</Suspense>
		</div>
	);
};

export default UpdateWeblinkStatusPage;
