import { useEffect, useState } from 'react';
import axios from 'axios';
import { GET_GEO_LOCATION_URL } from 'utils/serviceUrls';

const useGetGeoLocation = () => {
	const [geoLocationData, setGeoLocationData] = useState<any>([]);
	const [loader, setLoader] = useState<boolean>(true);
	useEffect(() => {
		setLoader(true)
		axios
			.get(GET_GEO_LOCATION_URL)
			.then((response: any) => {
				setGeoLocationData(response?.data);
			})
			.catch(() => {})
			.finally(()=>setLoader(false))
			;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return {
		geoLocationData,
		loader
	};
};
export default useGetGeoLocation;
