import { useTranslation } from 'react-i18next';

import { GridCellProps } from '@progress/kendo-react-grid';

const CustomCell = ({ dataItem }: any) => {
	const { t } = useTranslation('ProductFileModule');
	return (
		/**
		 * reason column in grid
		 */
		<td>
			{dataItem?.reason !== null && dataItem?.reason === 'PRODUCT_NAME_IS_NULL_NOTIFICATION'
				? t(dataItem?.reason, { rowNumber: dataItem?.rowNumber })
				: t(dataItem?.reason)}
		</td>
	);
};

const CustomReasonCell = (props: GridCellProps) => {
	// eslint-disable-next-line react/jsx-props-no-spreading
	return <CustomCell {...props} />;
};

export default CustomReasonCell;
