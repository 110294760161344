import axios, { AxiosInstance } from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import { browserName, browserVersion, osName, osVersion } from 'react-device-detect';
import { REFRESH_TOKEN } from 'utils/serviceUrls';
import { API_BASE_PATH } from '../../utils/config';
import {
	getBaseUrl,
	getBrowserLocation,
	getRefreshToken,
	getToken,
	removeLoginedUserData,
	removeToken,
	storeRefreshToken,
	storeToken,
} from '../../utils/utils';

// public instance of axios here
export const axiosBasicInstance: AxiosInstance = axios.create({
	baseURL: API_BASE_PATH,
});

// private instance of axios

export const axiosPrivateInstance: AxiosInstance = axios.create({
	baseURL: API_BASE_PATH,
});

// setting header for basic instance with interceptor

/**
 * setting base url and user agent long with body

 */
let commonBody = {
	url: getBaseUrl(),
	browser: `${browserName} ${browserVersion} on ${osName} ${osVersion}`,
	ipAddress: '',
};
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
	// dev code

	// to login as tenant Admin,  replace acg.com with tenant.com
	commonBody = {
		url: 'https://acgiqatest.acgi.in',
		browser: `${browserName} ${browserVersion} on ${osName} ${osVersion}`,
		ipAddress: '',
	};
} else {
	// production code
}
axiosBasicInstance.interceptors.request.use(
	async (request: any) => {
		const location: any = await getBrowserLocation();
		const { latitude, longitude } = location;
		request.headers = {
			// Authorization: getToken(),
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
		};
		// const latitude = location?.latitude;
		// const longitude = location?.longitude;

		request.data = { ...request.data, ...commonBody, latitude, longitude };
		return request;
	},
	(error) => Promise.reject(error),
);

// setting header for private instance with interceptor

axiosPrivateInstance.interceptors.request.use(
	async (request: any) => {
		const location: any = await getBrowserLocation();
		const { latitude, longitude } = location;
		request.headers = {
			Authorization: getToken(),
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
		};

		request.data = { ...request.data, ...commonBody, latitude, longitude };

		return request;
	},
	(error) => Promise.reject(error),
);

/**
 *
 * API service to get new token and refresh token
 */
const refreshTokenService = () => {
	return axiosBasicInstance
		.post(REFRESH_TOKEN, {
			token: getToken(),
			refreshToken: getRefreshToken(),
		})
		.catch((error) => {
			if (error) {
				localStorage.clear();
				window.location.href = '/';
			}
		});
};

// success handler

const handleSuccess = (response: any) => {
	if (response && response.status === 200) {
		return response.data;
	}
	return Promise.reject();
};

// error handler

const handleError = async (error: any) => {
	const originalConfig = error.config;

	// reject cancelled req
	if (error?.name === 'CanceledError') {
		return Promise.reject(error);
	}

	// Token expired
	if (error && error.response && error.response.status === 401 && !originalConfig._retry) {
		originalConfig._retry = true;

		try {
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			const rs: any = await refreshTokenService();

			if (rs && rs.output) {
				const { token, refreshToken } = rs.output;
				storeToken(token);
				storeRefreshToken(refreshToken);
				axiosPrivateInstance.defaults.headers.common.Authorization = token;

				return axiosPrivateInstance(originalConfig);
			}
		} catch (_error: any) {
			if (_error.response && _error.response.data) {
				return Promise.reject(_error.response.data);
			}

			return Promise.reject(_error);
		}
	}

	if (error && error.response && error.response.status === 403) {
		return error.response;
	}
	/**
	 * if APi status is 700 redirect to a seperate route
	 */
	if (error && error.response && error.response.status === 700) {
		/**
		 * navigating to the multiple-session route
		 * clearing local storage and user details
		 */
		removeLoginedUserData();
		removeToken();
		window.location.href = '/multiple-session';
		return error.response;
	}
	return Promise.reject(error);
};

// response handling with interceptor
axiosBasicInstance.interceptors.response.use(handleSuccess, handleError);
axiosPrivateInstance.interceptors.response.use(handleSuccess, handleError);

/**
 * Refresh token service
 */

// eslint-disable-next-line no-unused-vars
