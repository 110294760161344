import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, DrawerContent } from '@progress/kendo-react-layout';

import { useAppSelector } from 'hooks/stateHooks';
import { RouteUrl, Privileges } from 'utils/enum';
import { hasPrivileges } from 'utils/utils';
import { getUserLoginData } from 'slices/userLoginDataSlice';

import SidebarCustom from './SidebarCustom';
import { ISidebarItem, ISidebarPropType } from '../../components/common/model';

const Index = ({ children }: ISidebarPropType) => {
	const { t } = useTranslation('userModule');
	const getLoginUserData = useAppSelector(getUserLoginData);
	const [expanded, setExpanded] = useState<boolean>(true);
	const [RouterItems] = useState<any>([
		{ id: 0, text: '', selected: false, icon: '', isactive: 'true' },
		{
			id: 1,
			text: t('DASHBOARD'),
			selected: false,
			icon: 'faHome',
			route: RouteUrl.DASHBOARD,
			isactive: hasPrivileges(Privileges.DashboardView, getLoginUserData) ? 'true' : 'false',
		},
		{
			id: 2,
			text: t('USER_MODULE'),
			selected: false,
			icon: 'faChartPie',
			route: RouteUrl.USERMODULE,
			isactive: hasPrivileges(Privileges.UserMenuView, getLoginUserData) ? 'true' : 'false',
		},
		{
			id: 3,
			text: t('PRODUCT_MODULE'),
			selected: false,
			icon: 'faCube',
			route: RouteUrl.PRODUCTMODULE,
			'data-expanded': false,
			isactive: hasPrivileges(Privileges.ProductMenu, getLoginUserData) ? 'true' : 'false',
			children: [
				{
					id: 9,
					text: t('ADDPRODUCTS'),
					selected: false,
					icon: 'faCartPlus',
					route: RouteUrl.ADDPRODUCTS,
					isactive: 'false',
					className: 'submenu-1',
				},
				{
					id: 56,
					text: t('PRODUCT_FILE_MANAGEMENT'),
					selected: false,
					icon: 'faBoxesPacking',
					isactive: hasPrivileges(Privileges.ProductFileManagementMenu, getLoginUserData)
						? 'true'
						: 'false',
					route: RouteUrl.PRODUCT_FILE_MANAGEMENT,
					className: 'submenu-4',
				},
				{
					id: 10,
					text: t('PRODUCT_GROUP'),
					selected: false,
					icon: 'faLayerGroup',
					isactive: hasPrivileges(Privileges.ProductGroupMenu, getLoginUserData)
						? 'true'
						: 'false',
					route: RouteUrl.PRODUCTGROUP,
					className: 'submenu-2',
				},
				{
					id: 11,
					text: 'Add Product Group',
					selected: false,
					icon: 'faPlus',
					isactive: 'false', // no need to show
					route: RouteUrl.ADDPRODUCTGROUP,
				},
				{
					id: 12,
					text: t('MANUFAC_MANAGEMENT'),
					selected: false,
					icon: 'faIndustry',
					isactive: hasPrivileges(Privileges.ManufacturerMenu, getLoginUserData)
						? 'true'
						: 'false',
					route: RouteUrl.MANUFACTURER,
					className: 'submenu-3',
				},
				{
					id: 36,
					text: t('LOCATION_MANAGEMENT')!!,
					selected: false,
					icon: 'faLocationDot',
					route: RouteUrl.LOCATION_DETAILS,
					isactive: hasPrivileges(Privileges.LocationMenu, getLoginUserData)
						? 'true'
						: 'false',
				},
				{
					id: 13,
					text: t('PACKAGE_LEVEL'),
					selected: false,
					icon: 'faBoxesPacking',
					isactive: hasPrivileges(Privileges.PackageLevelMenu, getLoginUserData)
						? 'true'
						: 'false',
					route: RouteUrl.PACKAGELEVEL,
					className: 'submenu-4',
				},
				{
					id: 14,
					text: 'Product Version View',
					selected: false,
					icon: 'faPlus',
					isactive: 'false',
					route: RouteUrl.PRODUCT_VERSION_VIEW,
				},
				{
					id: 15,
					text: 'Product History Grid',
					selected: false,
					icon: 'faPlus',
					isactive: 'false',
					route: RouteUrl.PRODUCT_HISTORY_VIEW,
				},
				{
					id: 49,
					text: 'addEditManufacturer',
					selected: false,
					icon: 'faLocationArrow',
					isactive: false,
					route: RouteUrl.ADD_EDIT_MANUFACTURER,
				},
				{
					id: 50,
					text: 'viewManufacturer',
					selected: false,
					icon: 'faLocationArrow',
					isactive: false,
					route: RouteUrl.VIEW_MANUFACTURER,
				},
				{
					id: 39,
					text: 'addEditLocation',
					selected: false,
					icon: 'faBoxesPacking',
					isactive: false,
					route: RouteUrl.ADD_EDIT_LOCATION,
				},
				{
					id: 51,
					text: 'viewLocation',
					selected: false,
					icon: 'faBoxesPacking',
					isactive: false,
					route: RouteUrl.VIEW_LOCATION,
				},
				{
					id: 52,
					text: 'configurable',
					selected: false,
					icon: 'faPlus',
					isactive: false,
					route: RouteUrl.PRODUCT_GROUP_CONFIGURABLE,
				},
				{
					id: 57,
					text: 'ViewProductFileManagement',
					selected: false,
					icon: 'faBoxesPacking',
					isactive: false,
					route: RouteUrl.VIEW_PRODUCT_FILE_MANAGEMENT,
				},
			],
		},
		{
			id: 4,
			text: t('ROLE_MODULE'),
			selected: false,
			icon: 'faAddressCard',
			route: RouteUrl.ROLEMODULE,
			isactive: hasPrivileges(Privileges.RoleMenuView, getLoginUserData) ? 'true' : 'false',
		},
		{
			id: 5,
			text: t('TENANT'),
			selected: false,
			icon: 'faUser',
			route: RouteUrl.TENANTMODULE,
			isactive: hasPrivileges(Privileges.TenantMenuView, getLoginUserData) ? 'true' : 'false',
		},
		{
			id: 6,
			text: t('SETTINGS'),
			selected: false,
			icon: 'faGear',
			route: RouteUrl.SETTINGS,
			isactive: hasPrivileges(Privileges.SettingsMenuView, getLoginUserData)
				? 'true'
				: 'false',

			children: [
				{
					id: 45,
					text: t('WEBLINK_SETTINGS'),
					selected: false,
					icon: 'faLayerGroup',
					isactive: hasPrivileges(Privileges.weblinkSettingsMenu, getLoginUserData)
						? 'true'
						: 'false',
					route: RouteUrl.WEBLINK_SETTINGS,
					className: 'submenu-2',
				},
			],
		},
		{
			id: 14,
			text: t('L3_MANAGEMENT')!!,
			selected: true,
			icon: 'faNetworkWired',
			route: RouteUrl.L3SYSTEM,
			isactive: hasPrivileges(Privileges.L3systemMenu, getLoginUserData) ? 'true' : 'false',
			children: [
				{
					id: 15,
					text: t('CREATE_L3_SYSTEM'),
					selected: false,
					icon: 'faBoxesPacking',
					isactive: false,
					route: RouteUrl.ADDL3SYSTEM,
					className: 'submenu-4',
				},
				{
					id: 43,
					text: t('GENERATE WEBLINK DOWNLOAD')!!,
					selected: false,
					icon: 'faLocationCrosshairs',
					route: RouteUrl.GENERATE_WEBLINK_DOWNLOAD,
					isactive: 'false',
				},
				{
					id: 44,
					text: t('WEBLINK_DOWNLOAD')!!,
					selected: false,
					icon: 'faLocationCrosshairs',
					route: RouteUrl.WEBLINK_DOWNLOAD,
					isactive: hasPrivileges(Privileges.L3DownloadWeblinkMenu, getLoginUserData)
						? 'true'
						: 'false',
				},
			],
		},

		{
			id: 16,
			text: t('PRODUCT_ORDER_MAMAGEMANT'),
			selected: false,
			icon: 'faBoxesPacking',
			route: RouteUrl.PRODUCTORDER,
			isactive: hasPrivileges(Privileges.BatchPOMenu, getLoginUserData) ? 'true' : 'false',
			children: [
				{
					id: 21,
					text: t('PODETAILS'),
					selected: false,
					icon: 'faLink',
					route: RouteUrl.PODETAILS,
					isactive: 'false',
				},
				{
					id: 24,
					text: 'PO Retrieval',
					selected: false,
					icon: 'faLink',
					route: RouteUrl.PORETRIEVAL,
					isactive: 'false',
				},
				{
					id: 22,
					text: t('WEBLINK_STATUS'),
					selected: false,
					icon: 'faLink',
					route: RouteUrl.WEBLINKSTATUS,
					isactive: 'false',
				},
			],
		},
		{
			id: 17,
			text: t('WEBLINK_VALIDATION_AND_MANAGEMENT'),
			selected: false,
			icon: 'faLink',
			route: RouteUrl.WEBLINKVALIDATOR,
			isactive: hasPrivileges(Privileges.weblinkValidatorMenu, getLoginUserData)
				? 'true'
				: 'false',
			children: [
				{
					id: 56,
					text: t('UPDATE_WEBLINK_STATUS'),
					selected: false,
					icon: 'faLayerGroup',
					isactive: hasPrivileges(Privileges.udpateWeblinkStatusMenu, getLoginUserData)
						? 'true'
						: 'false',
					route: RouteUrl.UDPATE_WEBLINK_STATUS,
					className: 'submenu-2',
				},
				{
					id: 57,
					text: 'View Update Weblink Status',
					selected: false,
					icon: 'faPlus',
					isactive: 'false',
					route: RouteUrl.VIEW_UPDATE_WEBLINK_STATUS,
				},
			],
		},
		{
			id: 18,
			text: 'OTP Verification',
			selected: false,
			icon: 'faUser',
			isactive: 'false', // no need to show
			route: RouteUrl.OTPVERIFICATION,
		},
		{
			id: 19,
			text: t('AWAITING_ACTIONS'),
			selected: false,
			icon: 'faLink',
			route: RouteUrl.AWAITINGACTIONS,
			isactive: 'false',
		},
		{
			id: 20,
			text: t('EPCIS_FILE_HEADING'),
			selected: false,
			icon: 'faFile', // to be changed
			route: RouteUrl.EPCISMODULE,
			isactive: hasPrivileges(Privileges.EPCISMENU, getLoginUserData) ? 'true' : 'false',
		},
		{
			id: 22,
			text: 'file failure list',
			selected: false,
			icon: 'faLink',
			route: RouteUrl.FILE_FAILURE_LIST,
			isactive: 'false',
		},
		{
			id: 23,
			text: 'Notification list',
			selected: false,
			icon: 'faLink',
			route: RouteUrl.ALLNOTIFICATIONS,
			isactive: 'false',
		},

		{
			id: 27,
			text: t('AUDIT_TRIAL_REPORT'),
			selected: false,
			icon: 'faFileLines',
			route: RouteUrl.AUDIT_TRIAL,
			isactive: hasPrivileges(Privileges.AuditTrialMenu, getLoginUserData) ? 'true' : 'false',
			className: 'submenu-2',
			children: [
				{
					id: 31,
					text: 'Audit Trail Deatils',
					selected: false,
					icon: 'faLayerGroup',
					route: RouteUrl.AUDIT_SHOW,
					isactive: 'false',
				},
				{
					id: 32,
					text: 'Batch Deatils Report',
					selected: false,
					icon: 'faLayerGroup',
					route: RouteUrl.BATCH_DETAILS_SHOW,
					isactive: 'false',
				},
				{
					id: 33,
					text: t('BATCH_DETAILS_REPORT'),
					selected: false,
					icon: 'faFileLines',
					route: RouteUrl.BATCH_DETAILS,
					isactive: hasPrivileges(Privileges.BatchDetailMenu, getLoginUserData)
						? 'true'
						: 'false',
					className: 'submenu-2',
				},
				{
					id: 34,
					text: t('BATCH_SUMMARY_REPORT'),
					selected: false,
					icon: 'faFileLines',
					route: RouteUrl.BATCH_SUMMARY_REPORT,
					isactive: hasPrivileges(Privileges.BatchSummaryMenu, getLoginUserData)
						? 'false'
						: 'false',
					className: 'submenu-2',
				},
				{
					id: 35,
					text: 'generate batch summary',
					selected: false,
					icon: 'faFileLines',
					route: RouteUrl.GENERATE_BATCH_SUMMARY,
					isactive: 'false',
					className: 'submenu-2',
				},
				{
					id: 41,
					text: t('WEBLINK_REPORT'),
					selected: false,
					icon: 'faFileLines',
					route: RouteUrl.WEBLINK_REPORT,
					isactive: hasPrivileges(Privileges.WebLinkReportMenu, getLoginUserData)
						? 'true'
						: 'false',
					className: 'submenu-2',
				},
				{
					id: 42,
					text: t('ENDUSER_REPORT'),
					selected: false,
					icon: 'faFileLines',
					route: RouteUrl.ENDUSER_REPORT,
					isactive: hasPrivileges(Privileges.EndUserReportMenu, getLoginUserData)
						? 'true'
						: 'false',
					className: 'submenu-2',
				},
			],
		},
		{
			id: 28,
			text: 'reportAction',
			selected: false,
			icon: '',
			route: RouteUrl.REPORT_ACTION_PAGE,
			isactive: 'false',
		},

		// {
		// 	id: 7,
		// 	text: t('HELP'),
		// 	selected: true,
		// 	icon: 'faQuestion',
		// 	route: RouteUrl.SETTINGS,
		// 	isactive: hasPrivileges(Privileges.DashboardView, getLoginUserData) ? 'true' : 'false',
		// },
		// {
		// 	id: 8,
		// 	text: t('NOTIFICATIONS'),
		// 	selected: true,
		// 	icon: 'faBell',
		// 	route: RouteUrl.SETTINGS,
		// 	isactive: hasPrivileges(Privileges.DashboardView, getLoginUserData) ? 'true' : 'false',
		// },

		{
			id: 30,
			text: t('MODULE_CONFIGURATION'),
			selected: false,
			icon: 'faLayerGroup',
			route: RouteUrl.MODULECONFIGURATIONS,
			isactive: hasPrivileges(Privileges.ModuleConfigurationMenu, getLoginUserData)
				? 'true'
				: 'false',
		},

		{
			id: 37,
			text: t('L4_MANAGEMENT'),
			selected: false,
			icon: 'faServer',
			route: RouteUrl.L4SYSTEM,
			isactive: hasPrivileges(Privileges.L4SystemMenu, getLoginUserData) ? 'false' : 'false',
		},
		{
			id: 38,
			text: t('CREATE_L4_SYSTEM'),
			selected: false,
			icon: 'faBoxesPacking',
			isactive: false,
			route: RouteUrl.ADDL4SYSTEM,
		},
		{
			id: 40,
			text: 'exportHistory',
			selected: false,
			icon: 'faBoxesPacking',
			isactive: false,
			route: RouteUrl.EXPORT_HISTORY_PAGE,
		},

		{
			id: 55,
			text: t('SSO_MANAGEMENT')!!,
			selected: false,
			icon: 'faLocationCrosshairs',
			route: RouteUrl.SSO,
			isactive: hasPrivileges(Privileges.SSOMenu, getLoginUserData) ? 'true' : 'false',
		},
		{
			id: 46,
			text: t('SERIAL_NUMBER_MANAGEMENT'),
			selected: false,
			icon: 'faListOl',
			isactive: hasPrivileges(Privileges.SerialNumberMenu, getLoginUserData)
				? 'true'
				: 'false',
			route: RouteUrl.SERIAL_NUMBER_MANAGEMENT,
			children: [
				{
					id: 47,
					text: 'addSerialNumberTemplate',
					selected: false,
					icon: 'faBoxesPacking',
					isactive: false,
					route: RouteUrl.ADD_SERIAL_NUMBER_TEMPLATE,
				},
			],
		},
		{
			id: 48,
			text: 'productOrderSerialNumber',
			selected: false,
			icon: 'faBoxesPacking',
			isactive: false,
			route: RouteUrl.PRODUCT_ORDER_SERIAL_NUMBER,
		},
		// {
		// 	id: 41,
		// 	text: 'GS1 Weblink Settings',
		// 	selected: false,
		// 	icon: 'faGear',
		// 	isactive: 'false', // no need to show
		// 	route: RouteUrl.GS1_WEBLINK_SETTINGS,
		// 	className: 'submenu-2',
		// },
		{
			id: 54,
			text: 'GS1 Weblink Settings',
			selected: false,
			icon: 'faGear',
			isactive: 'false', // no need to show
			route: RouteUrl.GS1_WEBLINK_SETTINGS,
			className: 'submenu-2',
		},
		{
			id: 53,
			text: t('WEBLINK_FLUSHING'),
			selected: false,
			icon: 'faLink',
			route: RouteUrl.WEBLINK_FLUSHING,
			isactive: hasPrivileges(Privileges.WeblinkFlushMenu, getLoginUserData)
				? 'true'
				: 'false',
		},
	]);
	/**
	 * New sidebar order code. Remove the comment to get the new order side bar
	 */
	// const [RouterItems] = useState<any>([
	// 	{ id: 0, text: '', selected: false, icon: '', isactive: 'true' },
	// 	{
	// 		id: 1,
	// 		text: t('DASHBOARD'),
	// 		selected: false,
	// 		icon: 'faHome',
	// 		route: RouteUrl.DASHBOARD,
	// 		isactive: hasPrivileges(Privileges.DashboardView, getLoginUserData) ? 'true' : 'false',
	// 	},
	// 	{
	// 		id: 2,
	// 		text: t('USER_MODULE'),
	// 		selected: false,
	// 		icon: 'faChartPie',
	// 		route: RouteUrl.USERMODULE,
	// 		isactive: hasPrivileges(Privileges.UserMenuView, getLoginUserData) ? 'true' : 'false',
	// 		children: [
	// 			{
	// 				id: 4,
	// 				text: t('ROLE_MODULE'),
	// 				selected: false,
	// 				icon: 'faAddressCard',
	// 				route: RouteUrl.ROLEMODULE,
	// 				isactive: hasPrivileges(Privileges.RoleMenuView, getLoginUserData)
	// 					? 'true'
	// 					: 'false',
	// 			},
	// 		],
	// 	},
	// 	{
	// 		id: 3,
	// 		text: t('PRODUCT_MODULE'),
	// 		selected: false,
	// 		icon: 'faCube',
	// 		route: RouteUrl.PRODUCTMODULE,
	// 		'data-expanded': false,
	// 		isactive: hasPrivileges(Privileges.ProductMenu, getLoginUserData) ? 'true' : 'false',
	// 		children: [
	// 			{
	// 				id: 9,
	// 				text: t('ADDPRODUCTS'),
	// 				selected: false,
	// 				icon: 'faCartPlus',
	// 				route: RouteUrl.ADDPRODUCTS,
	// 				isactive: 'false',
	// 				className: 'submenu-1',
	// 			},
	// 			{
	// 				id: 10,
	// 				text: t('PRODUCT_GROUP'),
	// 				selected: false,
	// 				icon: 'faLayerGroup',
	// 				isactive: hasPrivileges(Privileges.ProductGroupMenu, getLoginUserData)
	// 					? 'true'
	// 					: 'false',
	// 				route: RouteUrl.PRODUCTGROUP,
	// 				className: 'submenu-2',
	// 			},
	// 			{
	// 				id: 11,
	// 				text: 'Add Product Group',
	// 				selected: false,
	// 				icon: 'faPlus',
	// 				isactive: 'false', // no need to show
	// 				route: RouteUrl.ADDPRODUCTGROUP,
	// 			},
	// 			{
	// 				id: 12,
	// 				text: t('MANUFAC_MANAGEMENT'),
	// 				selected: false,
	// 				icon: 'faIndustry',
	// 				isactive: hasPrivileges(Privileges.ManufacturerMenu, getLoginUserData)
	// 					? 'true'
	// 					: 'false',
	// 				route: RouteUrl.MANUFACTURER,
	// 				className: 'submenu-3',
	// 			},
	// 			{
	// 				id: 13,
	// 				text: t('PACKAGE_LEVEL'),
	// 				selected: false,
	// 				icon: 'faBoxesPacking',
	// 				isactive: hasPrivileges(Privileges.PackageLevelMenu, getLoginUserData)
	// 					? 'true'
	// 					: 'false',
	// 				route: RouteUrl.PACKAGELEVEL,
	// 				className: 'submenu-4',
	// 			},
	// 		],
	// 	},

	// 	{
	// 		id: 5,
	// 		text: t('TENANT'),
	// 		selected: false,
	// 		icon: 'faUser',
	// 		route: RouteUrl.TENANTMODULE,
	// 		isactive: hasPrivileges(Privileges.TenantMenuView, getLoginUserData) ? 'true' : 'false',
	// 	},

	// 	{
	// 		id: 16,
	// 		text: t('PRODUCT_ORDER_MAMAGEMANT'),
	// 		selected: false,
	// 		icon: 'faBoxesPacking',
	// 		route: RouteUrl.PRODUCTORDER,
	// 		isactive: hasPrivileges(Privileges.BatchPOMenu, getLoginUserData) ? 'true' : 'false',
	// 		children: [
	// 			{
	// 				id: 20,
	// 				text: t('EPCIS_FILE_HEADING'),
	// 				selected: false,
	// 				icon: 'faFile', // to be changed
	// 				route: RouteUrl.EPCISMODULE,
	// 				isactive: hasPrivileges(Privileges.EPCISMENU, getLoginUserData)
	// 					? 'true'
	// 					: 'false',
	// 			},
	// 			{
	// 				id: 14,
	// 				text: t('L3_MANAGEMENT')!!,
	// 				selected: true,
	// 				icon: 'faNetworkWired',
	// 				route: RouteUrl.L3SYSTEM,
	// 				isactive: hasPrivileges(Privileges.L3systemMenu, getLoginUserData)
	// 					? 'true'
	// 					: 'false',
	// 			},
	// 			{
	// 				id: 42,
	// 				text: t('SSO_MANAGEMENT')!!,
	// 				selected: false,
	// 				icon: 'faLocationCrosshairs',
	// 				route: RouteUrl.SSO,
	// 				isactive: hasPrivileges(Privileges.SSOMenu, getLoginUserData)
	// 					? 'true'
	// 					: 'false',
	// 			},

	// 			{
	// 				id: 15,
	// 				text: t('CREATE_L3_SYSTEM'),
	// 				selected: false,
	// 				icon: 'faBoxesPacking',
	// 				isactive: false,
	// 				route: RouteUrl.ADDL3SYSTEM,
	// 				className: 'submenu-4',
	// 			},

	// 			{
	// 				id: 21,
	// 				text: t('PODETAILS'),
	// 				selected: false,
	// 				icon: 'faLink',
	// 				route: RouteUrl.PODETAILS,
	// 				isactive: 'false',
	// 			},
	// 			{
	// 				id: 24,
	// 				text: 'PO Retrieval',
	// 				selected: false,
	// 				icon: 'faLink',
	// 				route: RouteUrl.PORETRIEVAL,
	// 				isactive: 'false',
	// 			},
	// 		],
	// 	},
	// 	{
	// 		id: 6,
	// 		text: t('SETTINGS'),
	// 		selected: false,
	// 		icon: 'faGear',
	// 		route: RouteUrl.SETTINGS,
	// 		isactive: hasPrivileges(Privileges.SettingsMenuView, getLoginUserData)
	// 			? 'true'
	// 			: 'false',
	// 	},
	// 	{
	// 		id: 17,
	// 		text: t('WEBLINK_VALIDATION_AND_MANAGEMENT'),
	// 		selected: false,
	// 		icon: 'faLink',
	// 		route: RouteUrl.WEBLINKVALIDATOR,
	// 		isactive: hasPrivileges(Privileges.weblinkValidatorMenu, getLoginUserData)
	// 			? 'true'
	// 			: 'false',
	// 		children: [
	// 			{
	// 				id: 43,
	// 				text: t('GENERATE WEBLINK DOWNLOAD')!!,
	// 				selected: false,
	// 				icon: 'faLocationCrosshairs',
	// 				route: RouteUrl.GENERATE_WEBLINK_DOWNLOAD,
	// 				isactive: 'false',
	// 			},
	// 			{
	// 				id: 44,
	// 				text: t('WEBLINK_DOWNLOAD')!!,
	// 				selected: false,
	// 				icon: 'faLocationCrosshairs',
	// 				route: RouteUrl.WEBLINK_DOWNLOAD,
	// 				isactive: hasPrivileges(Privileges.L3DownloadWeblinkMenu, getLoginUserData)
	// 					? 'true'
	// 					: 'false',
	// 			},
	// 		],
	// 	},
	// 	{
	// 		id: 18,
	// 		text: 'OTP Verification',
	// 		selected: false,
	// 		icon: 'faUser',
	// 		isactive: 'false', // no need to show
	// 		route: RouteUrl.OTPVERIFICATION,
	// 	},
	// 	{
	// 		id: 19,
	// 		text: t('AWAITING_ACTIONS'),
	// 		selected: false,
	// 		icon: 'faLink',
	// 		route: RouteUrl.AWAITINGACTIONS,
	// 		isactive: 'false',
	// 	},

	// 	{
	// 		id: 22,
	// 		text: t('WEBLINK_STATUS'),
	// 		selected: false,
	// 		icon: 'faLink',
	// 		route: RouteUrl.WEBLINKSTATUS,
	// 		isactive: 'false',
	// 	},

	// 	{
	// 		id: 22,
	// 		text: 'file failure list',
	// 		selected: false,
	// 		icon: 'faLink',
	// 		route: RouteUrl.FILE_FAILURE_LIST,
	// 		isactive: 'false',
	// 	},
	// 	{
	// 		id: 23,
	// 		text: 'Notification list',
	// 		selected: false,
	// 		icon: 'faLink',
	// 		route: RouteUrl.ALLNOTIFICATIONS,
	// 		isactive: 'false',
	// 	},

	// 	{
	// 		id: 27,
	// 		text: t('AUDIT_TRIAL_REPORT'),
	// 		selected: false,
	// 		icon: 'faFileLines',
	// 		route: RouteUrl.AUDIT_TRIAL,
	// 		isactive: hasPrivileges(Privileges.AuditTrialMenu, getLoginUserData) ? 'true' : 'false',
	// 		className: 'submenu-2',
	// 		children: [
	// 			{
	// 				id: 31,
	// 				text: 'Audit Trail Deatils',
	// 				selected: false,
	// 				icon: 'faLayerGroup',
	// 				route: RouteUrl.AUDIT_SHOW,
	// 				isactive: 'false',
	// 			},
	// 			{
	// 				id: 32,
	// 				text: 'Batch Deatils Report',
	// 				selected: false,
	// 				icon: 'faLayerGroup',
	// 				route: RouteUrl.BATCH_DETAILS_SHOW,
	// 				isactive: 'false',
	// 			},
	// 			{
	// 				id: 33,
	// 				text: t('BATCH_DETAILS_REPORT'),
	// 				selected: false,
	// 				icon: 'faFileLines',
	// 				route: RouteUrl.BATCH_DETAILS,
	// 				isactive: hasPrivileges(Privileges.BatchDetailMenu, getLoginUserData)
	// 					? 'true'
	// 					: 'false',
	// 				className: 'submenu-2',
	// 			},
	// 			{
	// 				id: 34,
	// 				text: t('BATCH_SUMMARY_REPORT'),
	// 				selected: false,
	// 				icon: 'faFileLines',
	// 				route: RouteUrl.BATCH_SUMMARY_REPORT,
	// 				isactive: hasPrivileges(Privileges.BatchSummaryMenu, getLoginUserData)
	// 					? 'false'
	// 					: 'false',
	// 				className: 'submenu-2',
	// 			},
	// 			{
	// 				id: 35,
	// 				text: 'generate batch summary',
	// 				selected: false,
	// 				icon: 'faFileLines',
	// 				route: RouteUrl.GENERATE_BATCH_SUMMARY,
	// 				isactive: 'false',
	// 				className: 'submenu-2',
	// 			},
	// 			{
	// 				id: 41,
	// 				text: t('WEBLINK_REPORT'),
	// 				selected: false,
	// 				icon: 'faFileLines',
	// 				route: RouteUrl.WEBLINK_REPORT,
	// 				isactive: hasPrivileges(Privileges.WebLinkReportMenu, getLoginUserData)
	// 					? 'true'
	// 					: 'false',
	// 				className: 'submenu-2',
	// 			},
	// 		],
	// 	},
	// 	{
	// 		id: 28,
	// 		text: 'reportAction',
	// 		selected: false,
	// 		icon: '',
	// 		route: RouteUrl.REPORT_ACTION_PAGE,
	// 		isactive: 'false',
	// 	},

	// 	// {
	// 	// 	id: 7,
	// 	// 	text: t('HELP'),
	// 	// 	selected: true,
	// 	// 	icon: 'faQuestion',
	// 	// 	route: RouteUrl.SETTINGS,
	// 	// 	isactive: hasPrivileges(Privileges.DashboardView, getLoginUserData) ? 'true' : 'false',
	// 	// },
	// 	// {
	// 	// 	id: 8,
	// 	// 	text: t('NOTIFICATIONS'),
	// 	// 	selected: true,
	// 	// 	icon: 'faBell',
	// 	// 	route: RouteUrl.SETTINGS,
	// 	// 	isactive: hasPrivileges(Privileges.DashboardView, getLoginUserData) ? 'true' : 'false',
	// 	// },

	// 	{
	// 		id: 30,
	// 		text: t('MODULE_CONFIGURATION'),
	// 		selected: false,
	// 		icon: 'faLayerGroup',
	// 		route: RouteUrl.MODULECONFIGURATIONS,
	// 		isactive: hasPrivileges(Privileges.ModuleConfigurationMenu, getLoginUserData)
	// 			? 'true'
	// 			: 'false',
	// 	},

	// 	{
	// 		id: 36,
	// 		text: t('LOCATION')!!,
	// 		selected: false,
	// 		icon: 'faLocationCrosshairs',
	// 		route: RouteUrl.LOCATION_DETAILS,
	// 		isactive: hasPrivileges(Privileges.LocationMenu, getLoginUserData) ? 'false' : 'false',
	// 	},
	// 	{
	// 		id: 37,
	// 		text: t('L4_MANAGEMENT'),
	// 		selected: false,
	// 		icon: 'faServer',
	// 		route: RouteUrl.L4SYSTEM,
	// 		isactive: hasPrivileges(Privileges.L4SystemMenu, getLoginUserData) ? 'false' : 'false',
	// 	},
	// 	{
	// 		id: 38,
	// 		text: t('CREATE_L4_SYSTEM'),
	// 		selected: false,
	// 		icon: 'faBoxesPacking',
	// 		isactive: false,
	// 		route: RouteUrl.ADDL4SYSTEM,
	// 	},
	// 	{
	// 		id: 39,
	// 		text: 'addEditLocation',
	// 		selected: false,
	// 		icon: 'faBoxesPacking',
	// 		isactive: false,
	// 		route: RouteUrl.ADD_EDIT_LOCATION,
	// 	},
	// 	{
	// 		id: 40,
	// 		text: 'exportHistory',
	// 		selected: false,
	// 		icon: 'faBoxesPacking',
	// 		isactive: false,
	// 		route: RouteUrl.EXPORT_HISTORY_PAGE,
	// 	},

	// 	{
	// 		id: 41,
	// 		text: 'GS1 Weblink Settings',
	// 		selected: false,
	// 		icon: 'faGear',
	// 		isactive: 'false', // no need to show
	// 		route: RouteUrl.GS1_WEBLINK_SETTINGS,
	// 		className: 'submenu-2',
	// 	},
	// ]);

	const handleClick = () => {
		setExpanded(!expanded);
	};

	/**
	 * Handler for DrawerSelectEvent.
	 * Sets expanded state to false.
	 */
	const onSelect = () => {
		setExpanded(false);
		// navigate(e.itemTarget.props.route);
	};

	/**
	 * Checks if any child sidebar item is active
	 * @param {ISidebarItem} childrenVal - The child items
	 * @returns - Whether any child is active
	 */
	const hasActiveChild = (childrenVal: ISidebarItem[] | undefined) => {
		return childrenVal?.some((child: ISidebarItem) => child?.isactive === 'true');
	};

	/**
	 * Filters items to only active items and their active children.
	 * @returns {ISidebarItem[]} - The filtered active items
	 */
	const activeItems = RouterItems.filter(
		(x: ISidebarItem) => x?.isactive === 'true' || hasActiveChild(x?.children),
	);

	return (
		<div className='page-wrapper'>
			{/* eslint-disable-next-line react/button-has-type, jsx-a11y/control-has-associated-label */}
			<button
				type='button'
				onClick={() => handleClick()}
				className='k-button k-button-md k-rounded-md k-button-flat k-button-flat-base sidebar-toggle-btn'>
				<span className='k-icon k-i-menu' />
			</button>

			<Drawer
				expanded={expanded}
				mode='push'
				position='start'
				mini
				items={activeItems}
				item={SidebarCustom}
				onSelect={() => onSelect()}>
				<div>
					<p className='acg-version'>{process.env.REACT_APP_VERSION}</p>
				</div>
				<DrawerContent className=''>{children}</DrawerContent>
			</Drawer>
		</div>
	);
};

export default Index;
