import { useEffect, useState } from 'react';

import { axiosPrivateInstance } from 'libs/axios';
import { GET_FILE_HISTORY_DATA_URL } from 'utils/serviceUrls';

const useGetFileHistoryData = (encLotNoId: string, refreshGrid: boolean) => {
	const [fileHistoryResponseData, setFileHistoryResponseData] = useState<any>(null);
	const [fileHistoryLoading, setFileHistoryLoading] = useState<boolean>(false);

	const params = {
		encLotNumberId: encLotNoId,
	};

	useEffect(() => {
		setFileHistoryLoading(true);
		axiosPrivateInstance
			.post(GET_FILE_HISTORY_DATA_URL, params)
			.then((response: any) => {
				setFileHistoryResponseData(response.output);
				setFileHistoryLoading(false);
			})
			.catch(() => {
				setFileHistoryLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (refreshGrid) {
			setFileHistoryLoading(true);
			axiosPrivateInstance
				.post(GET_FILE_HISTORY_DATA_URL, params)
				.then((response: any) => {
					setFileHistoryResponseData(response.output);
					setFileHistoryLoading(false);
				})
				.catch(() => {
					setFileHistoryLoading(false);
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refreshGrid]);

	return {
		fileHistoryResponseData,
		fileHistoryLoading,
	};
};

export default useGetFileHistoryData;
