import { Suspense, lazy } from 'react';
import CommonSkeleton from 'components/common/skeleton/CommonSkeleton';

const ProductFileManagement = lazy(
	() => import('components/productModule/productFileManagement/Index'),
);

const ProductFileManagementPage = () => {
	return (
		<div className='page-content user-module'>
			<Suspense fallback={<CommonSkeleton />}>
				<ProductFileManagement />
			</Suspense>
		</div>
	);
};

export default ProductFileManagementPage;
