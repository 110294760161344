import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'hooks/stateHooks';

import CustomButton from 'components/common/button/CustomButton';
import {
	getAddress,
	getCountry,
	getDescription,
	getL3SystemName,
	setAddress,
	setConnectionMethodHost,
	setConnectionMethodPassword,
	setConnectionMethodPortno,
	setConnectionMethodTenantid,
	setConnectionMethodUsername,
	setCountry,
	setDescription,
	setL3SystemName,
	setSoapPassword,
	setSoapUsername,
	setWeblinkIdentifierLimit,
	setWeblinkInclusionCharacters,
	setweblinkPoolSize,
	getConnectionMethodHost,
	getConnectionMethodPassword,
	getConnectionMethodPortno,
	getConnectionMethodTenantid,
	getConnectionMethodUsername,
	getSelectedConnectionType,
	getSoapIdentifier,
	getSoapPassword,
	getSoapUsername,
	getWeblinkIdentifierLimit,
	getWeblinkInclusionCharacters,
	getweblinkPoolSize,
	setCreateL3SubmitButtonStatus,
	setUpdateL3SubmitButtonStatus,
	getL3Location,
	setL3Location,
	setSftpPasswordErrorMsg,
	setSoapPasswordErrMsg,
	setSelectedConnectionType,
	setAvailablePoolSize,
} from 'slices/l3SystemSlice';
import passwordValidation, { hasPrivileges, productFormValidations } from 'utils/utils';
import { ICON_DESCRIPTION_AREA_ID, MODULE_IDS, Privileges, RouteUrl } from 'utils/enum';
import { getUserLoginData } from 'slices/userLoginDataSlice';
import {
	getModulesRequireAuth,
	getPasswordCapital,
	getPasswordMaxChar,
	getPasswordMinChar,
	getPasswordNumeric,
	getPasswordSpecialChar,
} from 'slices/configSlice';
import { setL3SystemIconsData } from 'slices/infoIconsDataSlice';

import CustomLoader from 'components/common/loader/CustomLoader';
import CreateL3Skeleton from 'components/common/skeleton/CreateL3Skeleton';
import PageBackButton from 'components/common/pageBackButton/PageBackButton';
import PasswordVerification from 'components/login/PasswordVerification';
import useGetIconDescription from 'components/common/tooltip/hooks/useGetIconDescription';
import useAddL3System from './hooks/useAddL3System';
import useUpdateL3System from './hooks/useUpdateL3System';
import useGetL3SystemById from './hooks/useGetL3SystemById';
import * as constants from '../../utils/constants';
import CreateL3Config from './createL3Config/CreateL3Config';
import ConnectionMethod from './createL3Config/ConnectionMethod';
import SoapConfiguration from './createL3Config/SoapConfiguration';
import WeblinkSettings from './createL3Config/WeblinkSettings';

const AddEditL3System = () => {
	const { t } = useTranslation('l3System');
	const navigate = useNavigate();

	const location = useLocation();

	const [isPasswordVerification, setIsPasswordVerification] = useState<boolean>(false);
	const modulesRequireAuth = useAppSelector(getModulesRequireAuth);

	const getLoginUserData = useAppSelector(getUserLoginData);
	const dispatch = useAppDispatch();
	const selectedConnectionType = useAppSelector(getSelectedConnectionType);
	const l3SystemName = useAppSelector(getL3SystemName);
	const address = useAppSelector(getAddress);
	const description = useAppSelector(getDescription);
	const l3Location = useAppSelector(getL3Location);
	const country = useAppSelector(getCountry);
	const sftpHost = useAppSelector(getConnectionMethodHost);
	const sftpUsername = useAppSelector(getConnectionMethodUsername);
	const sftpPassword = useAppSelector(getConnectionMethodPassword);
	const sftpPortNo = useAppSelector(getConnectionMethodPortno);
	const restApiTenantId = useAppSelector(getConnectionMethodTenantid);
	const soapUsername = useAppSelector(getSoapUsername);
	const soapPassword = useAppSelector(getSoapPassword);
	const soapIdentifier = useAppSelector(getSoapIdentifier);
	const weblinkInclusionCharacters = useAppSelector(getWeblinkInclusionCharacters);
	const weblinkIdentifierLimit = useAppSelector(getWeblinkIdentifierLimit);
	const weblinkPoolSize = useAppSelector(getweblinkPoolSize);

	const [errorState, setErrorState] = useState<Record<string, string>>({});
	const [l3ById, setL3ById] = useState<any>(0);

	const configPasswordMinLength = useAppSelector(getPasswordMinChar);
	const configPasswordMaxLength = useAppSelector(getPasswordMaxChar);
	const configPasswordNumeric = useAppSelector(getPasswordNumeric);
	const configPasswordOneCapital = useAppSelector(getPasswordCapital);
	const configPasswordSpecialChar = useAppSelector(getPasswordSpecialChar);
	const getUserDetails = useAppSelector(getUserLoginData);

	const [buttonDisable, setButtonDisable] = useState<boolean>(false);

	const { resData, l3Loading } = useGetL3SystemById(l3ById);

	const { addpL3Response, loading } = useAddL3System({
		l3SystemName: l3SystemName?.trim(),
		address: address?.trim(),
		description: description?.trim(),
		country,
		sftpHost: sftpHost?.trim(),
		sftpUsername: sftpUsername?.trim(),
		sftpPassword: sftpPassword?.trim(),
		sftpPortNo,
		soapUsername: soapUsername?.trim(),
		soapPassword: soapPassword?.trim(),
		soapIdentifier: soapIdentifier?.trim(),
		weblinkPoolSize,
		restApiTenantId: restApiTenantId?.trim(),
		selectedConnectionType,
		l3Location: l3Location?.trim(),
	});

	const { updateL3Response, updateL3loading } = useUpdateL3System({
		encSystemID: l3ById,
		l3SystemName: l3SystemName?.trim(),
		address: address?.trim(),
		encCountryId: country?.encCountryId,
		description: description?.trim(),
		selectedConnectionType,
		country,
		sftpHost: sftpHost?.trim(),
		sftpUsername: sftpUsername?.trim(),
		sftpPassword: sftpPassword?.trim(),
		sftpPortNo,
		soapUsername: soapUsername?.trim(),
		soapPassword: soapPassword?.trim(),
		soapIdentifier: soapIdentifier?.trim(),
		weblinkPoolSize,
		restApiTenantId: restApiTenantId?.trim(),
		l3Location: l3Location?.trim(),
	});

	const { iconDescriptionResponse } = useGetIconDescription(ICON_DESCRIPTION_AREA_ID.L3_SYSTEM);

	// sets icon description data in state
	useEffect(() => {
		if (iconDescriptionResponse) {
			dispatch(setL3SystemIconsData(iconDescriptionResponse?.output));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [iconDescriptionResponse]);

	const onSaveButtonClick = () => {
		const error = productFormValidations(
			{
				l3SystemName,
				address,
				description,
				country,
				sftpHost,
				sftpUsername,
				sftpPassword,
				sftpPortNo,
				restApiTenantId,
				soapUsername,
				soapPassword,
				weblinkInclusionCharacters,
				weblinkIdentifierLimit,
				weblinkPoolSize,
			},
			{
				l3SystemName: {
					required: true,
					maxlength: 150,
				},
				address: {
					required: true,
					maxlength: 250,
				},
				description: {
					required: true,
					maxlength: 250,
				},
				country: {
					required: true,
				},
				sftpHost: {
					required: selectedConnectionType === constants.SFTP,
					maxlength: 150,
				},
				sftpUsername: {
					required: true,
					// maxlength: 150,
					// alphanumericWithSpace: true,
				},
				sftpPassword: {
					required: true,
				},
				restApiTenantId: {
					required: selectedConnectionType === constants.REST_API,
				},
				soapUsername: {
					required: true,
					maxlength: 150,
					alphanumericWithSpace: true,
				},
				soapPassword: {
					required: true,
				},

				weblinkPoolSize: {
					numbers: true,
					negativeValue: true,
					required: true,
					maxValue: 1000000,
					minValue: 1,
				},
				sftpPortNo: {
					required: selectedConnectionType === constants.SFTP,
				},
			},
		);
		// const sftpPasswordError = passwordValidation(sftpPassword, {
		// 	passwordMinLength: configPasswordMinLength,
		// 	passwordMaxLength: configPasswordMaxLength,
		// 	passwordNumeric: configPasswordNumeric,
		// 	passwordCapital: configPasswordOneCapital,
		// 	passwordSpecialChar: configPasswordSpecialChar,
		// });
		// if (sftpPasswordError !== '') {
		// 	dispatch(setSftpPasswordErrorMsg(sftpPasswordError));
		// }
		const soapPasswordError = passwordValidation(soapPassword, {
			passwordMinLength: configPasswordMinLength,
			passwordMaxLength: configPasswordMaxLength,
			passwordNumeric: configPasswordNumeric,
			passwordCapital: configPasswordOneCapital,
			passwordSpecialChar: configPasswordSpecialChar,
		});

		// soap and sftp password validations are different from other state validations
		if (soapPasswordError !== '') {
			dispatch(setSoapPasswordErrMsg(soapPasswordError));
		}
		setErrorState(error);

		if (l3ById !== 0) {
			if (
				Object.keys(error)?.length === 0 &&
				// sftpPasswordError === '' &&
				soapPasswordError === ''
			) {
				if (modulesRequireAuth.includes(MODULE_IDS.L3_MANAGEMENT)) {
					setIsPasswordVerification(true);
				} else {
					dispatch(setUpdateL3SubmitButtonStatus(true));
				}
			}
		} else if (
			Object.keys(error)?.length === 0 &&
			// sftpPasswordError === '' &&
			soapPasswordError === ''
		) {
			if (modulesRequireAuth.includes(MODULE_IDS.L3_MANAGEMENT)) {
				setIsPasswordVerification(true);
			} else {
				dispatch(setCreateL3SubmitButtonStatus(true));
			}
		}
	};

	useEffect(() => {
		if (l3SystemName !== '') {
			setErrorState((prev) => ({ ...prev, l3SystemName: '' }));
		}
		if (address !== '') {
			setErrorState((prev) => ({ ...prev, address: '' }));
		}
		if (description !== '') {
			setErrorState((prev) => ({ ...prev, description: '' }));
		}
		if (country !== '') {
			setErrorState((prev) => ({ ...prev, country: '' }));
		}
		if (sftpHost !== '') {
			setErrorState((prev) => ({ ...prev, sftpHost: '' }));
		}
		if (sftpUsername !== '') {
			setErrorState((prev) => ({ ...prev, sftpUsername: '' }));
		}
		if (sftpPassword !== '') {
			setErrorState((prev) => ({ ...prev, sftpPassword: '' }));
		}

		if (restApiTenantId !== '') {
			setErrorState((prev) => ({ ...prev, restApiTenantId: '' }));
		}
		if (soapUsername !== '') {
			setErrorState((prev) => ({ ...prev, soapUsername: '' }));
		}

		if (sftpPortNo !== '') {
			setErrorState((prev) => ({ ...prev, sftpPortNo: '' }));
		}
		if (weblinkPoolSize !== '') {
			setErrorState((prev) => ({ ...prev, weblinkPoolSize: '' }));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		l3SystemName,
		address,
		description,
		country,
		sftpHost,
		sftpUsername,
		sftpPassword,
		restApiTenantId,
		soapUsername,
		soapPassword,
		sftpPortNo,
		weblinkInclusionCharacters,
		weblinkIdentifierLimit,
		weblinkPoolSize,
	]);

	// to clear sftp and soap password err msgs when changed page
	useEffect(() => {
		return () => {
			dispatch(setSftpPasswordErrorMsg(''));
			dispatch(setSoapPasswordErrMsg(''));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// to clear connection method err msg when method changed
	const clearConnectionMethodErrMsgs = () => {
		setErrorState((prev) => ({ ...prev, sftpHost: '' }));
		setErrorState((prev) => ({ ...prev, sftpUsername: '' }));
		setErrorState((prev) => ({ ...prev, soapUsername: '' }));
		dispatch(setSftpPasswordErrorMsg(''));
		setErrorState((prev) => ({ ...prev, sftpPortNo: '' }));
	};

	// to show the validation msg of country when selected please select option
	useEffect(() => {
		if (country.encCountryId === '0') {
			dispatch(setCountry(''));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [country]);

	useEffect(() => {
		setL3ById(location?.state && location?.state?.id);
	}, [location?.state]);

	const clearAllFields = () => {
		dispatch(setL3SystemName(''));
		dispatch(setAddress(''));
		dispatch(setDescription(''));
		dispatch(setCountry(''));
		dispatch(setL3Location(''));
		dispatch(setConnectionMethodHost(''));
		dispatch(setConnectionMethodUsername(''));
		dispatch(setConnectionMethodPassword(''));
		dispatch(setConnectionMethodPortno(''));
		dispatch(setConnectionMethodTenantid(''));
		dispatch(setSoapUsername(''));
		dispatch(setSoapPassword(''));
		dispatch(setWeblinkInclusionCharacters(''));
		dispatch(setWeblinkIdentifierLimit(''));
		dispatch(setweblinkPoolSize(''));
		dispatch(setAvailablePoolSize(''));
	};

	useEffect(() => {
		if (resData && resData.length > 0) {
			dispatch(setL3SystemName(resData[0]?.l3SystemName));
			dispatch(setAddress(resData[0]?.address));
			dispatch(setDescription(resData[0]?.description));
			dispatch(setCountry(resData[0]?.encCountryId));
			dispatch(setL3Location(resData[0]?.location));
			dispatch(setSelectedConnectionType(resData[0]?.connectionMethodID));
			dispatch(setConnectionMethodHost(resData[0]?.connectionDetails?.host));
			dispatch(setConnectionMethodUsername(resData[0]?.connectionDetails?.userName));
			dispatch(setConnectionMethodPassword(resData[0]?.connectionDetails?.password));
			dispatch(setConnectionMethodPortno(resData[0]?.connectionDetails?.portNo));
			dispatch(
				setConnectionMethodTenantid(
					resData[0]?.connectionDetails?.identifier !== ''
						? resData[0]?.connectionDetails?.identifier
						: getUserDetails?.tenantCode,
				),
			);

			dispatch(setSoapUsername(resData[0]?.soapConfigurationDetails?.userName));
			dispatch(setSoapPassword(resData[0]?.soapConfigurationDetails?.password));

			dispatch(
				setWeblinkInclusionCharacters(
					resData[0]?.webLinkSettingsDetails?.inclusionCharacters,
				),
			);
			dispatch(
				setWeblinkIdentifierLimit(
					resData[0]?.webLinkSettingsDetails?.weblinkidentifierLimit,
				),
			);
			dispatch(setweblinkPoolSize(resData[0]?.webLinkSettingsDetails?.weblinkPoolSize));
			dispatch(setAvailablePoolSize(resData[0]?.availablePoolSize));
		}

		return () => {
			clearAllFields();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [l3ById, resData]);

	useEffect(() => {
		if (addpL3Response) {
			if (addpL3Response?.status) {
				dispatch(setCreateL3SubmitButtonStatus(false));
				navigate(RouteUrl.L3SYSTEM);
			} else {
				dispatch(setCreateL3SubmitButtonStatus(false));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addpL3Response]);

	useEffect(() => {
		if (updateL3Response) {
			if (updateL3Response?.status) {
				dispatch(setUpdateL3SubmitButtonStatus(false));
				navigate(RouteUrl.L3SYSTEM);
			} else {
				dispatch(setUpdateL3SubmitButtonStatus(false));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateL3Response]);

	const handleToggle = () => {
		setIsPasswordVerification(false);
	};

	/**
	 * fuction for handle verification success
	 */
	const handleVerificationSuccess = () => {
		handleToggle();
		if (l3ById !== 0) {
			dispatch(setUpdateL3SubmitButtonStatus(true));
		} else {
			dispatch(setCreateL3SubmitButtonStatus(true));
		}
	};

	const getButtonDisable = (disabled: boolean) => {
		setButtonDisable(disabled);
	};

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			<div className='page-content'>
				<div className='page-header back-btn-header'>
					<div className='back-btn-header'>
						<PageBackButton url={RouteUrl.L3SYSTEM} />
						<h3>{t('L3_SYSTEM_CREATION')}</h3>
					</div>
				</div>

				<div className='page-content-body common-page-content-body'>
					<div className='box-container create-l3-box-container'>
						{l3Loading ? (
							<CreateL3Skeleton />
						) : (
							<>
								<div className='default-form-style'>
									<div className='row'>
										<div className='col-lg-6 col-md-6'>
											<CreateL3Config
												errorState={errorState}
												l3ById={l3ById}
											/>
										</div>
										<div className='col-lg-6 col-md-6'>
											<ConnectionMethod
												errorState={errorState}
												clearErrMessage={clearConnectionMethodErrMsgs}
												getButtonDisable={getButtonDisable}
											/>
										</div>
									</div>
									<div className='row'>
										<div className='col-lg-6 col-md-6'>
											<SoapConfiguration errorState={errorState} />
										</div>
										<div className='col-lg-6 col-md-6'>
											<WeblinkSettings errorState={errorState} />
										</div>
									</div>
								</div>
								<div className='btn-footer-wrapper'>
									{hasPrivileges(Privileges.AddL3System, getLoginUserData) && (
										<CustomButton
											disabled={buttonDisable || loading || updateL3loading}
											buttonClassName='btn btn-primary h-44px icon-add-btn'
											buttonName={
												loading || updateL3loading ? (
													<CustomLoader />
												) : (
													t(
														l3ById !== 0
															? `${t('UPDATE')}`
															: `${t('CREATE')}`,
													)
												)
											}
											handleClick={onSaveButtonClick}
											type='button'
										/>
									)}
								</div>
							</>
						)}
					</div>
				</div>
			</div>

			{/* Password verification component */}
			{isPasswordVerification && modulesRequireAuth.includes(MODULE_IDS.L3_MANAGEMENT) && (
				<PasswordVerification
					onVerificationSuccess={handleVerificationSuccess}
					toggleDialog={handleToggle}
				/>
			)}
		</>
	);
};

export default AddEditL3System;
