import { memo } from 'react';

import { Tooltip } from '@progress/kendo-react-tooltip';
import { useTranslation } from 'react-i18next';

import { IcommonGridRefreshButtonPropType } from '../model';
import CustomButton from './CustomButton';

/**
 *
 * @param clearAllFilters tfunction to clear all filters applied on grid
 * Reset button common component to reset all filters applied on grid and to reload grid
 */
const CustomGridResetButton = ({ clearAllFilters }: IcommonGridRefreshButtonPropType) => {
	const { t } = useTranslation('dashboard');
	/** handle function used to clear all filters */
	const onResetButtonClick = () => {
		clearAllFilters();
	};

	return (
		<Tooltip parentTitle position='top'>
			<span title={t('RESET')!!}>
				<CustomButton
					buttonClassName='btn btn-outline-secondary h-44px w-auto refresh-btn ms-2'
					type='button'
					handleClick={onResetButtonClick}
					icon='faArrowRotateLeft'
				/>
			</span>
		</Tooltip>
	);
};

export default memo(CustomGridResetButton);
