import { useTranslation } from 'react-i18next';

import { Grid, GridToolbar, GridFilterChangeEvent } from '@progress/kendo-react-grid';
import { Input } from '@progress/kendo-react-inputs';
import { filterBy, orderBy } from '@progress/kendo-data-query';

import { filterOperators } from 'utils/utils';
import NoData from 'pages/NoData';

import { SMALLGRIDPAGESIZE } from 'utils/constants';

import CustomGridRefreshButton from '../button/CustomGridRefreshButton';
import CustomGridResetButton from '../button/CustomGridResetButton';
import {
	gridCommonPageChange,
	gridCommonSortChange,
	onCommonGridFilterChange,
} from './GridCommonFunctions';
import GridSkeleton from '../skeleton/GridSkeleton';
import { ICommonGridComponentPropType } from '../model';

/**
 * Common component wrapper for all grid components with reset and refresh button
 * @param mainGridData - main grid data state
 * @param mainGridDataCopy - main grid data copy state for search functionality
 * @param gridLoadingState  main loading state for grid
 * @param setRefreshGrid - refresh grid state updating function
 * @param filter - filter state of grid
 * @param setFilter - state updating function for filter
 * @param page - page state of grid
 * @param setPage - state updating function for page
 * @param pagesizeValue - page size state of grid
 * @param setPageSizeValue - page size state updating function
 * @param sortData - sortdata state of grid
 * @param setSortData - sortdate state updating function of grid
 * @param searchValue - search value state of search input
 * @param clearAllFilters - filter clearing function for reset button functionality
 * @param performGridSearch - grid search common function with custom column names
 * @param cellRender - cellrender function for grid
 * @param classname -  grid comon outer wrapper classname
 * @param children - pass columns of grid as children without any outer wrapper
 * @returns grid wrapper element which accepts columns as children
 */
const CommonGridComponent = ({
	mainGridData,
	mainGridDataCopy,
	gridLoadingState,
	setRefreshGrid,
	filter,
	setFilter,
	page,
	setPage,
	pagesizeValue,
	setPageSizeValue,
	sortData,
	setSortData,
	searchValue,
	clearAllFilters,
	performGridSearch,
	cellRender,
	classname,
	children,
}: ICommonGridComponentPropType) => {
	/** userModule translation module file is used for all grid translation keys */
	const { t } = useTranslation('userModule');

	/** function to call the grid common sort function */
	const handleGridSortChange = (e: any) => {
		gridCommonSortChange(e, setSortData);
	};

	/** function to call the grid common page change function */
	const handleGridPageChange = (e: any) => {
		gridCommonPageChange(e, setPageSizeValue, setPage);
	};

	/** function to call the grid common filter function */
	const handleGridFilterChange = (e: any) => {
		onCommonGridFilterChange(e, setFilter, page, setPage, SMALLGRIDPAGESIZE);
	};

	/** function to call the grid common search function */
	const handleGridSearchChange = (e: any) => {
		performGridSearch(e, mainGridDataCopy);
	};

	return (
		<>
			{/*  eslint-disable-next-line react/jsx-no-useless-fragment */}
			{gridLoadingState ? (
				<GridSkeleton />
			) : (
				// eslint-disable-next-line react/jsx-no-useless-fragment
				<div className={classname}>
					{mainGridData && mainGridDataCopy?.length > 0 ? (
						<Grid
							sortable={{ allowUnsort: true }}
							className='default-kendo-grid'
							onSortChange={(e: any) => handleGridSortChange(e)}
							style={{ height: '420px' }}
							data={filterBy(orderBy(mainGridData, sortData), filter)?.slice(
								page.skip,
								page.take + page.skip,
							)}
							skip={page.skip}
							sort={sortData}
							take={page.take}
							total={filterBy(mainGridData, filter).length}
							pageable={{
								buttonCount: 5,
								info: false,
								type: 'numeric',
								pageSizes: false,
								previousNext: true,
								pageSizeValue: pagesizeValue,
							}}
							onPageChange={(e: any) => handleGridPageChange(e)}
							filter={filter}
							filterOperators={filterOperators}
							onFilterChange={(e: GridFilterChangeEvent) => handleGridFilterChange(e)}
							cellRender={cellRender || undefined}>
							<GridToolbar className='default-kendoGrid-toolbar'>
								<div className='default-form-style w-100'>
									<CustomGridRefreshButton setRefreshGrid={setRefreshGrid} />
									<CustomGridResetButton clearAllFilters={clearAllFilters} />
									<div className='form-group search-box'>
										<label className='form-label'>{t('SEARCH')}</label>
										<Input
											className='form-control h-44px'
											value={searchValue}
											onChange={(e: any) => handleGridSearchChange(e)}
										/>
									</div>
								</div>
							</GridToolbar>

							{children}
						</Grid>
					) : (
						<NoData />
					)}
				</div>
			)}
		</>
	);
};

export default CommonGridComponent;
