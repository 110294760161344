import { Suspense, lazy } from 'react';
import CommonSkeleton from 'components/common/skeleton/CommonSkeleton';

const UpdateWeblinkView = lazy(
	() => import('components/l3System/update-weblink/update-weblink-view/ViewUpdateWeblink'),
);

const UpdateWeblinkViewPage = () => {
	return (
		<div className='page-content user-module'>
			<Suspense fallback={<CommonSkeleton />}>
				<UpdateWeblinkView />
			</Suspense>
		</div>
	);
};

export default UpdateWeblinkViewPage;
