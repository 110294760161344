import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: any = {
	weblinkCreatedDateValue: '',
	weblinkCreatedDateFilterValue: '',
	weblinkImportStatus: false,
};

export const updateWeblinkStatusSlice = createSlice({
	name: 'updateWeblinkStatus',
	initialState,
	reducers: {
		setWeblinkCreatedDateValue: (state, action: PayloadAction<any>) => {
			state.weblinkCreatedDateValue = action.payload;
		},
		setWeblinkCreatedDateFilterValue: (state, action: PayloadAction<any>) => {
			state.weblinkCreatedDateFilterValue = action.payload;
		},
		setWeblinkImportStatus: (state, action: PayloadAction<any>) => {
			state.weblinkImportStatus = action.payload;
		},
	},
});

export const {
	setWeblinkCreatedDateValue,
	setWeblinkCreatedDateFilterValue,
	setWeblinkImportStatus,
} = updateWeblinkStatusSlice.actions;

export const getWeblinkCreatedDateValue = (state: RootState) =>
	state.updateWeblinkStatus.weblinkCreatedDateValue;
export const getWeblinkCreatedDateFilterValue = (state: RootState) =>
	state.updateWeblinkStatus.weblinkCreatedDateFilterValue;
export const getWeblinkImportStatus = (state: RootState) =>
	state.updateWeblinkStatus.weblinkImportStatus;

export default updateWeblinkStatusSlice.reducer;
