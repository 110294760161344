import { useEffect, useState } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from 'react-router';

import { useTranslation } from 'react-i18next';

import { GridColumn as Column } from '@progress/kendo-react-grid';

import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';

import {
	gridCommonHeaderCellWithSortOption,
	gridCommonHeaderCellWithoutFilterOption,
	onCommonGridSearchWithFieldName,
} from 'components/common/grid/GridCommonFunctions';

import { ICON_DESCRIPTION_AREA_ID, ICON_DESCRIPTION_ID, RouteUrl } from 'utils/enum';

import { PageState } from 'components/common/model';
import { columnProps, sortUpandDown } from 'utils/utils';

import { SMALLGRIDPAGESIZE } from 'utils/constants';
import useGetIconDescription from 'components/common/tooltip/hooks/useGetIconDescription';
import CustomEditableTooltip from 'components/common/tooltip/CustomEditableTooltip';

import CommonGridComponent from 'components/common/grid/CommonGridComponent';

import PageBackButton from 'components/common/pageBackButton/PageBackButton';
import useGetAllProductFileDetails from './hooks/useGetAllProductFileDetails';
import CustomReasonCell from './CustomReasonCell';

const initialDataState: PageState = { skip: 0, take: SMALLGRIDPAGESIZE };
const initialFilter: CompositeFilterDescriptor = {
	logic: 'and',
	filters: [{ field: 'fileName', operator: 'contains', value: '' }],
};

const ViewProductFileManagement = () => {
	const { t } = useTranslation('ProductFileModule');
	const location = useLocation();

	const [page, setPage] = useState<PageState>(initialDataState);
	const [sortData, setSortData] = useState<Array<SortDescriptor>>([
		{ field: 'slNo', dir: 'asc' },
	]);
	const [filter, setFilter] = useState(initialFilter);
	const [sortDirection, setSortDirection] = useState<boolean>(true);
	const [pagesizeValue, setPageSizeValue] = useState<number | string | undefined>();
	const [searchValue, setSearchValue] = useState<any>();

	const [viewProductFileInfo, setViewProductFileInfo] = useState<string>('');
	const [productFileLoading, setProductFileLoading] = useState<boolean>(true);

	const [productFileId, setProductFileId] = useState<string>('');

	const [productFileDetailsData, setProductFileDetailsData] = useState<any>([]);
	const [productFileDetailsCopyData, setProductFileDetailsCopyData] = useState<any>([]);

	/** state for grid refresh and reset */
	const [refreshGrid, setRefreshGrid] = useState<boolean>(false);

	/**
	 * hook for listing Product File Details in grid
	 */
	const { productFileDetailsLoading, productFileDetailsList } = useGetAllProductFileDetails(
		productFileId,
		refreshGrid,
	);
	/**
	 * API call for icon description
	 */
	const { iconDescriptionResponse } = useGetIconDescription(
		ICON_DESCRIPTION_AREA_ID.PRODUCT,
		ICON_DESCRIPTION_ID.VIEW_PRODUCT_FILE,
	);

	// sets icon description data in state
	useEffect(() => {
		if (iconDescriptionResponse) {
			// eslint-disable-next-line no-unused-expressions
			iconDescriptionResponse?.output &&
				setViewProductFileInfo(iconDescriptionResponse?.output[0]?.iconDescription);
		}
	}, [iconDescriptionResponse]);

	/**
	 * fetching ID from location
	 */
	useEffect(() => {
		setProductFileId(location?.state && location?.state?.id);
	}, [location]);

	/**
	 * Performs a grid search based on the provided event and  data.
	 *
	 * @param {any} event - The event triggering the search.
	 * @param {any} actionReportData - The data used for the grid search.
	 * @returns {void}
	 */
	const performGridSearch = (event: any, data: any) => {
		onCommonGridSearchWithFieldName(
			event,
			setSearchValue,
			data,
			setProductFileDetailsData,
			page,
			setPage,
			SMALLGRIDPAGESIZE,
			['slNo', 'productName', 'fileName', 'statusName', 'searchReason'],
		);
	};

	// product file details data setting to state
	useEffect(() => {
		if (productFileDetailsList) {
			const tempData = productFileDetailsList?.map(
				(item: any, index: number) => ({
					...item,
					slNo: index + 1,
					statusName: item?.status ? t('COMPLETED') : t('FAILED'),
					searchReason:
						item?.reason !== null &&
						item?.reason === 'PRODUCT_NAME_IS_NULL_NOTIFICATION'
							? t(item?.reason, { rowNumber: item?.rowNumber })
							: t(item?.reason),
				}),

				[],
			);
			// for getting searched data after refresh
			if (searchValue && tempData) {
				// Apply search filtering if a search value exists
				performGridSearch({ value: searchValue }, tempData);
			}
			// If no search value, set the full dataset
			else {
				setProductFileDetailsData(tempData);
			}
			setProductFileDetailsCopyData(tempData);
		}
		// sets the loading state
		setProductFileLoading(productFileDetailsLoading);
		setRefreshGrid(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productFileDetailsList, productFileDetailsLoading]);

	useEffect(() => {
		if (searchValue === '') {
			setProductFileDetailsData(productFileDetailsCopyData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue]);

	/**
	 * function for handle sorting
	 * @param field
	 * @param dir
	 */
	const handleSort = (field: any, dir: any) => {
		const dt = sortData;
		dt[0].field = field;
		dt[0].dir = dir ? 'asc' : 'desc';

		setSortDirection(!dir);

		setSortData(dt);
	};

	useEffect(() => {
		sortUpandDown(sortData);
	}, [sortData]);

	useEffect(() => {
		sortUpandDown(sortData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortDirection]);

	/** to clear all filters applied on grid when reset button is clicked and to reload the data */
	const clearAllFilters = () => {
		setFilter(initialFilter);
		setSortData([{ field: 'slNo', dir: 'asc' }]);
		setPage(initialDataState);
		setSearchValue('');
		setRefreshGrid(true);
	};

	return (
		<div className='page-content user-module'>
			<div className='page-header'>
				<div className='back-btn-header'>
					<PageBackButton url={RouteUrl.PRODUCT_FILE_MANAGEMENT} />
					<h3>
						{t('VIEW_PRODUCT_FILE')}
						<CustomEditableTooltip
							showIcon='faInfoCircle'
							text={viewProductFileInfo}
							moduleId={ICON_DESCRIPTION_ID.VIEW_PRODUCT_FILE}
						/>
					</h3>
				</div>
			</div>
			<div className='page-content-body'>
				<div className='box-container userlist-box-container grid-custom-container'>
					<CommonGridComponent
						mainGridData={productFileDetailsData}
						mainGridDataCopy={productFileDetailsCopyData}
						gridLoadingState={productFileLoading}
						setRefreshGrid={setRefreshGrid}
						filter={filter}
						setFilter={setFilter}
						page={page}
						setPage={setPage}
						pagesizeValue={pagesizeValue}
						setPageSizeValue={setPageSizeValue}
						sortData={sortData}
						setSortData={setSortData}
						searchValue={searchValue}
						clearAllFilters={clearAllFilters}
						performGridSearch={performGridSearch}
						classname='grid-custom-container-inner with-toolbar'>
						<Column
							field='slNo'
							title={t('SL_NO')!!}
							width='100px'
							headerCell={(props: any) =>
								gridCommonHeaderCellWithoutFilterOption(
									props,
									handleSort,
									sortDirection,
									sortData,
								)
							}
							sortable
						/>
						<Column
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...columnProps('productName', filter)}
							sortable
							filterable
							title={t('PRODUCT_NAME')!!}
							headerCell={(props: any) =>
								gridCommonHeaderCellWithSortOption(
									props,
									handleSort,
									sortDirection,
									sortData,
								)
							}
							width='200px'
							filter='text'
						/>
						<Column
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...columnProps('fileName', filter)}
							title={t('FILE_NAME')!!}
							width='200px'
							sortable
							headerCell={(props: any) =>
								gridCommonHeaderCellWithSortOption(
									props,
									handleSort,
									sortDirection,
									sortData,
								)
							}
							filter='text'
						/>
						<Column
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...columnProps('statusName', filter)}
							field='statusName'
							title={t('STATUS')!!}
							headerCell={(props: any) =>
								gridCommonHeaderCellWithSortOption(
									props,
									handleSort,
									sortDirection,
									sortData,
								)
							}
							width='140px'
							filterable
						/>

						<Column
							cell={CustomReasonCell}
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...columnProps('searchReason', filter)}
							title={t('REASON')!!}
							headerCell={(props: any) =>
								gridCommonHeaderCellWithSortOption(
									props,
									handleSort,
									sortDirection,
									sortData,
								)
							}
							width='260px'
							filterable
						/>
					</CommonGridComponent>
				</div>
			</div>
		</div>
	);
};

export default ViewProductFileManagement;
