import { memo } from 'react';

import { Tooltip } from '@progress/kendo-react-tooltip';
import { useTranslation } from 'react-i18next';
import CustomButton from './CustomButton';
import { IcustomGridRefreshButtonPropType } from '../model';

/**
 *
 * @param setRefreshGrid // function to trigger the refresh button status state
 * Refresh button common component to refresh grid
 */
const CustomGridRefreshButton = ({ setRefreshGrid }: IcustomGridRefreshButtonPropType) => {
	const { t } = useTranslation('dashboard');
	/** refresh button handler function */
	const onRefreshButtonClick = () => {
		setRefreshGrid(true);
	};

	return (
		<Tooltip parentTitle position='top'>
			<span title={t('REFRESH')!!}>
				<CustomButton
					buttonClassName='btn btn-outline-secondary h-44px w-auto refresh-btn'
					type='button'
					handleClick={onRefreshButtonClick}
					icon='faArrowsRotate'
				/>
			</span>
		</Tooltip>
	);
};

export default memo(CustomGridRefreshButton);
