import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { axiosBasicInstance } from 'libs/axios';
import { GET_SCAN_HISTORY_URL } from 'utils/serviceUrls';
import { useAppDispatch } from 'hooks/stateHooks';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';

const useGetScanHistory = (encWebLinkId: any, isRequired: boolean) => {
	const { t } = useTranslation('endUserScan');
	const dispatch = useAppDispatch();

	const [scanHistoryDataRes, setScanHistoryDataRes] = useState<any>({});
	const [scanHistoryLoading, setScanHistoryLoading] = useState<boolean>(true);
	const params = {
		encWebLinkID: encWebLinkId,
	};

	useEffect(() => {
		if (encWebLinkId !== null && isRequired) {
			setScanHistoryLoading(true);
			axiosBasicInstance
				.post(GET_SCAN_HISTORY_URL, params)
				.then((response: any) => {
					setScanHistoryLoading(false);
					if (response?.output && response?.status) {
						setScanHistoryDataRes(response?.output);
					} else {
						dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
						dispatch(setShowNotification(true));
						dispatch(setNotificationType('error'));
					}
				})
				.catch(() => {
					setScanHistoryLoading(false);
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType('error'));
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [encWebLinkId]);

	return {
		scanHistoryDataRes,
		scanHistoryLoading,
	};
};

export default useGetScanHistory;
