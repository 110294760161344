import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { axiosPrivateInstance } from 'libs/axios';
import { GET_CONFIGURED_SCAN_ATTRIBUTES } from 'utils/serviceUrls';
import { useAppDispatch } from 'hooks/stateHooks';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';

const useGetConfiguredScanAttributes = (formData, weblinkValidScanStatus: any) => {
	const { t } = useTranslation('endUserScan');
	const dispatch = useAppDispatch();

	const [productGroupFieldsDataRes, setProductGroupFieldsDataRes] = useState<any>([]);
	const [productGroupFieldsDataLoading, setProductGroupFieldsDataLoading] =
		useState<boolean>(true);
	const params = {
		encProductId: formData?.endUserScanDataRes && formData?.endUserScanDataRes?.encProductId,
		encVersionId: formData?.endUserScanDataRes && formData?.endUserScanDataRes?.encVersionId,
	};

	useEffect(() => {
		if (weblinkValidScanStatus) {
			setProductGroupFieldsDataLoading(true);
			axiosPrivateInstance
				.post(GET_CONFIGURED_SCAN_ATTRIBUTES, params)
				.then((response: any) => {
					if (response?.output && response?.status) {
						setProductGroupFieldsDataRes(response?.output);
					} else {
						dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
						dispatch(setShowNotification(true));
						dispatch(setNotificationType('error'));
					}
				})
				.catch(() => {
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType('error'));
				})
				.finally(() => {
					setProductGroupFieldsDataLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [weblinkValidScanStatus]);
	return {
		productGroupFieldsDataLoading,
		productGroupFieldsDataRes,
	};
};

export default useGetConfiguredScanAttributes;
