import LoginLogo from 'assets/images/ALSC-Logo-menu.svg';
import Index from 'components/otpVerification/Index';

const OtpVerificationPage = () => {
	return (
		<div className='login-form otp-form'>
			<div className='logo-box-sm'>
				<img src={LoginLogo} alt='Panax Pharma' />
			</div>
			<h3> </h3>
			{/* <h6>Sign in to continue</h6> */}
			<Index />
		</div>
	);
};

export default OtpVerificationPage;
