import { useState, memo, useEffect, useCallback } from 'react';
import { getModulesRequireAuth } from 'slices/configSlice';
import { ListView, ListViewEvent } from '@progress/kendo-react-listview';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks/stateHooks';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import PasswordVerification from 'components/login/PasswordVerification';
import CustomButton from 'components/common/button/CustomButton';
import {
	ICON_DESCRIPTION_AREA_ID,
	ICON_DESCRIPTION_ID,
	MODE_VALUES,
	MODULE_IDS,
	PASSWORD_VERIFICATION_ACTIONS,
	PASSWORD_VERIFICATION_TRANSACTIONS,
	Privileges,
	RouteUrl,
	WEBLINK_STATUS,
	WEBLINK_TYPE,
	WEBLINK_TYPE_ID,
	WEBLINK_WORKFLOW_TYPE_ID,
} from 'utils/enum';
import CustomLoader from 'components/common/loader/CustomLoader';
import CustomhtmlButton from 'components/common/button/CustomhtmlButton';
import { hasPrivileges } from 'utils/utils';
import { getUserLoginData } from 'slices/userLoginDataSlice';
import PageBackButton from 'components/common/pageBackButton/PageBackButton';
import WeblinkSkeleton from 'components/common/skeleton/WeblinkSkeleton';
import NoData from 'pages/NoData';
import useGetIconDescription from 'components/common/tooltip/hooks/useGetIconDescription';
import CustomEditableTooltip from 'components/common/tooltip/CustomEditableTooltip';
import CustomCheckBox from 'components/common/forms/CustomCheckBox';
import { IAllWeblinkDataType } from 'components/common/model';
import useGetAllWebLinks from './hooks/useGetAllWebLinks';
import useWeblinkStatusUpdate from './hooks/useWeblinkStatusUpdate';
import updateApproveWeblinkStatusService from './services/updateApproveWeblinkStatusService ';
import updateReturnedWeblinkStatusService from './services/updateReturnedWeblinkStatusService';
import updateReviewWeblinkStatusService from './services/updateReviewWeblinkStatusService';
import DetailCard from './DetailCard';
import CustomWeblinkList from './CustomWeblinkList';

const WeblinkPage = () => {
	// States
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const getLoginUserData = useAppSelector(getUserLoginData);
	const { t } = useTranslation('batchPOModule');
	const modulesRequireAuth = useAppSelector(getModulesRequireAuth);
	const [selectedWeblinks, setSelectedWeblinks] = useState<string[]>([]);
	const [buttonType, setButtonType] = useState<string>('');
	const [messageVal, setMessageVal] = useState<string>('Action');
	const [mode, setMode] = useState<'activate' | 'deactivate' | 'view' | 'approve' | 'review'>(
		'activate',
	);
	const [type, setType] = useState<'product' | 'productorder' | 'dashboard'>('product');
	const [encPOId, setEncPOId] = useState<any>('');
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
	const [isWeblinkActive, setIsWeblinkActive] = useState<boolean>(false);
	const [approveLoading, setApproveLoading] = useState<boolean>(false);
	const [reviewLoading, setReviewLoading] = useState<boolean>(false);
	const [rejectLoading, setRejectLoading] = useState<boolean>(false);
	const [isPasswordVerification, setIsPasswordVerification] = useState<boolean>(false);
	const [passwordVerificationActions, setPasswordVerificationActions] = useState<number>();

	const [weblinkInfo, setWeblinkInfo] = useState<string>('');
	const [weblinkData, setWeblinkData] = useState<any>([]);
	const [weblinkCopy, setWeblinkCopy] = useState<any>([]);

	const [infoIconAreaID, setInfoIconAreaID] = useState<string>('');
	const [infoIconId, setInfoIconId] = useState<string>('');
	// state to toggle the checkboxes
	const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);

	// function to pass the activate/deactivate status to api
	const isActiveRequestFn = () => {
		let status: boolean;
		if (location?.state?.mode === MODE_VALUES.ACTIVATE) {
			status = true;
		} else if (location?.state?.mode === MODE_VALUES.DEACTIVATE) {
			status = false;
		} else {
			/**
			 *  if clicked activate, passing true to api both review/approve, if clicked deactivate , passing false to api in both review/approve
			 */
			status = !location?.state?.isWeblinkActive;
		}
		return status;
	};

	const { weblinkList, loading } = useGetAllWebLinks({
		encId: location?.state?.id,
		typeId:
			location?.state?.type === WEBLINK_TYPE.PRODUCT
				? WEBLINK_TYPE_ID.PRODUCT
				: WEBLINK_TYPE_ID.PRODUCT_ORDER,
		// new key for passing activate/deactivate state to fetch the data
		isActivateRequest: isActiveRequestFn(),
	});

	const handleWeblinkChange = (selected: any) => {
		setSelectedWeblinks(selected);
	};
	// eslint-disable-next-line react/no-unstable-nested-components
	const MyCustomStateCell = useCallback(
		(props: any) => (
			<CustomWeblinkList
				item={props.dataItem}
				selectedWeblinks={selectedWeblinks}
				mode={mode}
				onChange={handleWeblinkChange}
			/>
		),
		[mode, selectedWeblinks],
	);

	useEffect(() => {
		if (location?.state) {
			setMode(location?.state?.mode);
			setEncPOId(location?.state?.id);
			setType(location?.state?.type);
			setIsWeblinkActive(location?.state?.isWeblinkActive);
		}
	}, [location]);
	useEffect(() => {
		if (weblinkList) {
			setWeblinkCopy(weblinkList?.lstWeblinks);
		}
	}, [weblinkList]);

	useEffect(() => {
		if (weblinkCopy) {
			setWeblinkData(weblinkCopy.splice(0, 40));
		}
	}, [weblinkCopy]);

	useEffect(() => {
		if (location?.state?.type === WEBLINK_TYPE.PRODUCT) {
			setInfoIconAreaID(ICON_DESCRIPTION_AREA_ID.PRODUCT);
			setInfoIconId(ICON_DESCRIPTION_ID.PRODUCT_WEBLINK);
		} else if (location?.state?.type === WEBLINK_TYPE.PRODUCT_ORDER) {
			setInfoIconAreaID(ICON_DESCRIPTION_AREA_ID.PRODUCT_ORDER);
			setInfoIconId(ICON_DESCRIPTION_ID.PO_WEBLINK);
		}
	}, [location?.state?.type]);

	const { iconDescriptionResponse } = useGetIconDescription(infoIconAreaID, infoIconId);

	// sets icon description data in state
	useEffect(() => {
		if (iconDescriptionResponse) {
			// eslint-disable-next-line no-unused-expressions
			setWeblinkInfo(iconDescriptionResponse?.output[0]?.iconDescription);
		}
	}, [iconDescriptionResponse]);

	const updateWeblinksStatus = () => {
		setIsSubmitted(false);
		setMessageVal('Action');
	};

	const { updateWeblinkStatus, loadingUpdateWeblinkStatus } = useWeblinkStatusUpdate(
		isSubmitted,
		{
			encId: encPOId,
			encWebLinkIds: selectAllChecked ? [] : selectedWeblinks,
			workFlowTypeId:
				mode === MODE_VALUES.ACTIVATE
					? WEBLINK_WORKFLOW_TYPE_ID.ACTIVATE
					: WEBLINK_WORKFLOW_TYPE_ID.DEACTIVATE,
			typeId:
				location?.state?.type === WEBLINK_TYPE.PRODUCT
					? WEBLINK_TYPE_ID.PRODUCT
					: WEBLINK_TYPE_ID.PRODUCT_ORDER,
			comment: messageVal,
			isSelectAllEnabled: selectAllChecked,
		},
		updateWeblinksStatus,
	);

	useEffect(() => {
		if (updateWeblinkStatus) {
			if (type === WEBLINK_TYPE.PRODUCT) navigate(RouteUrl.PRODUCTMODULE);
			else navigate(RouteUrl.PRODUCTORDER);
		}
	}, [navigate, type, updateWeblinkStatus]);

	useEffect(() => {
		if (weblinkList) {
			const selectedIds: any = [];
			weblinkList?.lstWeblinks.map((item: any) => {
				if (
					item.activeStatusId === WEBLINK_STATUS.INITIATED ||
					item.activeStatusId === WEBLINK_STATUS.REVIEWED
				) {
					selectedIds.push(item.encWeblinkId);
				}
				return false;
			});

			setSelectedWeblinks(selectedIds);
		}
	}, [weblinkList]);

	const handleSubmit = () => {
		const error: any = {};
		if (selectedWeblinks.length === 0) {
			error.selectedWeblinks = t('PLEASE_SELECT_ATLEAST_ONE_WEBLINK');
			dispatch(setMessage(t('PLEASE_SELECT_ATLEAST_ONE_WEBLINK')));
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('warning'));
		}

		if (Object.keys(error).length === 0) {
			if (mode === MODE_VALUES.ACTIVATE) {
				if (
					type === WEBLINK_TYPE.PRODUCT_ORDER
						? modulesRequireAuth.includes(MODULE_IDS.PRODUCT_ORDER_MANAGEMENT)
						: modulesRequireAuth.includes(MODULE_IDS.PRODUCT_MANAGEMENT)
				) {
					setButtonType(MODE_VALUES.ACTIVATE);
					setPasswordVerificationActions(PASSWORD_VERIFICATION_ACTIONS.ACTIVE);
					setIsPasswordVerification(true);
				} else {
					setIsSubmitted(true);
				}
			} else if (
				type === WEBLINK_TYPE.PRODUCT_ORDER
					? modulesRequireAuth.includes(MODULE_IDS.PRODUCT_ORDER_MANAGEMENT)
					: modulesRequireAuth.includes(MODULE_IDS.PRODUCT_MANAGEMENT)
			) {
				setButtonType(MODE_VALUES.DEACTIVATE);
				setPasswordVerificationActions(PASSWORD_VERIFICATION_ACTIONS.INACTIVE);
				setIsPasswordVerification(true);
			} else {
				setIsSubmitted(true);
			}
		}
	};

	/**
	 *API call for update return status
	 * @param id API call for update return status
	 * @param message
	 */
	const updateReturnStatus = async (id: any, message: any) => {
		setRejectLoading(true);

		const tempWorkflow = !isWeblinkActive
			? WEBLINK_WORKFLOW_TYPE_ID.ACTIVATE
			: WEBLINK_WORKFLOW_TYPE_ID.DEACTIVATE;
		const tempTypeId =
			type === WEBLINK_TYPE.PRODUCT ? WEBLINK_TYPE_ID.PRODUCT : WEBLINK_TYPE_ID.PRODUCT_ORDER;
		const returnStatusRes = await updateReturnedWeblinkStatusService(
			id,
			message,
			tempWorkflow,
			tempTypeId,
		);
		if (returnStatusRes?.output?.status) {
			setRejectLoading(false);

			dispatch(
				setMessage(t(returnStatusRes?.output?.resultText || 'WEBLINK_REJECT_SUCCESS')),
			);
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('success'));
			if (type === WEBLINK_TYPE.PRODUCT) navigate(RouteUrl.PRODUCTMODULE);
			else navigate(RouteUrl.PRODUCTORDER);
		} else {
			setRejectLoading(false);

			dispatch(setMessage(t(returnStatusRes?.output?.resultText || 'WEBLINK_REJECT_FAILED')));
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('error'));
		}
	};

	/**
	 * API call for update approve status
	 * @param id
	 * @param message
	 */
	const updateApproveStatus = async (id: string, message: string) => {
		setApproveLoading(true);

		const tempWorkflow = !isWeblinkActive
			? WEBLINK_WORKFLOW_TYPE_ID.ACTIVATE
			: WEBLINK_WORKFLOW_TYPE_ID.DEACTIVATE;
		const tempTypeId =
			type === WEBLINK_TYPE.PRODUCT ? WEBLINK_TYPE_ID.PRODUCT : WEBLINK_TYPE_ID.PRODUCT_ORDER;

		const approveStatusRes = await updateApproveWeblinkStatusService(
			id,
			message,
			tempWorkflow,
			tempTypeId,
		);
		if (approveStatusRes?.output?.status) {
			setApproveLoading(false);

			dispatch(
				setMessage(t(approveStatusRes?.output?.resultText || 'WEBLINK_APPROVE_SUCCESS')),
			);
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('success'));
			if (type === WEBLINK_TYPE.PRODUCT) navigate(RouteUrl.PRODUCTMODULE);
			else navigate(RouteUrl.PRODUCTORDER);
		} else {
			setApproveLoading(false);
			dispatch(
				setMessage(t(approveStatusRes?.output?.resultText || 'WEBLINK_APPROVE_FAILED')),
			);
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('error'));
		}
	};

	/**
	 * API call for update review status
	 * @param id
	 * @param message
	 */
	const updateReviewStatus = async (id: any, message: any) => {
		setReviewLoading(true);
		const tempWorkflow = !isWeblinkActive
			? WEBLINK_WORKFLOW_TYPE_ID.ACTIVATE
			: WEBLINK_WORKFLOW_TYPE_ID.DEACTIVATE;
		const tempTypeId =
			type === WEBLINK_TYPE.PRODUCT ? WEBLINK_TYPE_ID.PRODUCT : WEBLINK_TYPE_ID.PRODUCT_ORDER;
		const reviewStatus = await updateReviewWeblinkStatusService(
			id,
			message,
			tempWorkflow,
			tempTypeId,
		);
		if (reviewStatus?.output?.status) {
			setReviewLoading(false);
			dispatch(setMessage(t(reviewStatus?.output?.resultText || 'WEBLINK_REVIEW_SUCCESS')));
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('success'));
			if (type === WEBLINK_TYPE.PRODUCT) navigate(RouteUrl.PRODUCTMODULE);
			else navigate(RouteUrl.PRODUCTORDER);
		} else {
			setReviewLoading(false);
			dispatch(setMessage(t(reviewStatus?.output?.resultText || 'WEBLINK_REVIEW_FAILED')));
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('error'));
		}
	};

	/** handle product review */
	const handleReview = () => {
		if (
			type === WEBLINK_TYPE.PRODUCT_ORDER
				? modulesRequireAuth.includes(MODULE_IDS.PRODUCT_ORDER_MANAGEMENT)
				: modulesRequireAuth.includes(MODULE_IDS.PRODUCT_MANAGEMENT)
		) {
			setButtonType(MODE_VALUES.REVIEW);
			setPasswordVerificationActions(
				isWeblinkActive
					? PASSWORD_VERIFICATION_ACTIONS.DEACTIVATION_REVIEW
					: PASSWORD_VERIFICATION_ACTIONS.ACTIVATION_REVIEW,
			);
			setIsPasswordVerification(true);
		} else {
			const message = 'Reviewed';
			updateReviewStatus(encPOId, message);
		}
	};

	/** handle product approve */
	const handleApprove = () => {
		if (
			type === WEBLINK_TYPE.PRODUCT_ORDER
				? modulesRequireAuth.includes(MODULE_IDS.PRODUCT_ORDER_MANAGEMENT)
				: modulesRequireAuth.includes(MODULE_IDS.PRODUCT_MANAGEMENT)
		) {
			setButtonType(MODE_VALUES.APPROVE);
			setPasswordVerificationActions(
				isWeblinkActive
					? PASSWORD_VERIFICATION_ACTIONS.DEACTIVATION_APPROVE
					: PASSWORD_VERIFICATION_ACTIONS.ACTIVATION_APPROVE,
			);
			setIsPasswordVerification(true);
		} else {
			const message = 'Approved';
			updateApproveStatus(encPOId, message);
		}
	};

	const handleReturn = () => {
		if (
			type === WEBLINK_TYPE.PRODUCT_ORDER
				? modulesRequireAuth.includes(MODULE_IDS.PRODUCT_ORDER_MANAGEMENT)
				: modulesRequireAuth.includes(MODULE_IDS.PRODUCT_MANAGEMENT)
		) {
			setButtonType(MODE_VALUES.REJECT);
			setPasswordVerificationActions(
				isWeblinkActive
					? PASSWORD_VERIFICATION_ACTIONS.DEACTIVATION_RETURN
					: PASSWORD_VERIFICATION_ACTIONS.ACTIVATION_RETURN,
			);
			setIsPasswordVerification(true);
		} else {
			const message = 'Rejected';
			updateReturnStatus(encPOId, message);
		}
	};

	const handleToggle = () => {
		setIsPasswordVerification(false);
	};

	const handleVerificationSuccess = (remark: string) => {
		if (buttonType === MODE_VALUES.APPROVE) {
			updateApproveStatus(encPOId, remark);
		} else if (buttonType === MODE_VALUES.REVIEW) {
			updateReviewStatus(encPOId, remark);
		} else if (buttonType === MODE_VALUES.ACTIVATE) {
			setIsSubmitted(true);
		} else if (buttonType === MODE_VALUES.DEACTIVATE) {
			setIsSubmitted(true);
			setMessageVal(remark);
		} else {
			updateReturnStatus(encPOId, remark);
		}
		handleToggle();
	};
	const scrollHandler = (event: ListViewEvent) => {
		const e = event.nativeEvent;
		if (e.target.scrollTop + 10 >= e.target.scrollHeight - e.target.clientHeight) {
			const moreData = weblinkCopy.splice(0, 25);
			if (moreData.length > 0) {
				setWeblinkData(weblinkData.concat(moreData));
			}
		}
	};

	/**
	 * common function to get all the weblink ids
	 */
	const getAllWeblinkIds = useCallback(() => {
		const allWeblinksIds = weblinkData?.map(
			(weblink: IAllWeblinkDataType) => weblink?.encWeblinkId,
		);
		return allWeblinksIds;
	}, [weblinkData]);

	/**
	 * function toggles the selection of all checkboxes
	 */
	const handleSelectAllChange = () => {
		const allSelected = selectedWeblinks?.length === getAllWeblinkIds()?.length;
		const newSelectedWeblinks = allSelected ? [] : getAllWeblinkIds();
		setSelectAllChecked(!allSelected);
		setSelectedWeblinks(newSelectedWeblinks);
	};

	/**
	 * updates the Select All checkbox when the selectedWeblinks changes
	 */
	useEffect(() => {
		const allSelected = selectedWeblinks?.length === getAllWeblinkIds()?.length;
		setSelectAllChecked(allSelected);
	}, [selectedWeblinks, getAllWeblinkIds]);

	return (
		<>
			<div className='page-content add-product-group-module'>
				<div className='page-header'>
					<div className='back-btn-header'>
						<PageBackButton url={-1} />
						<h3 className='canvas-title'>
							{t('WEBLINKS')}
							<CustomEditableTooltip
								showIcon='faInfoCircle'
								text={weblinkInfo}
								moduleId={infoIconId}
							/>
						</h3>
					</div>
				</div>
				<div className='page-content-body'>
					<div className='box-container weblink-box-container'>
						{loading ? (
							<WeblinkSkeleton />
						) : (
							// eslint-disable-next-line react/jsx-no-useless-fragment
							<>
								{weblinkList ? (
									<form>
										<div className='row weblink-row'>
											<div className='col-lg-4 weblink-left-box'>
												<DetailCard type={type} resData={weblinkList} />
											</div>
											<div className='col-lg-8 weblink-right-box'>
												<div className='right-scroll-wrapper'>
													<fieldset className='k-form-fieldset default-form-style tenant-content-body'>
														<div className='form-group'>
															<CustomCheckBox
																className='select-all-check'
																checked={selectAllChecked}
																name='selectAll'
																labelPlacement='after'
																label={t('SELECT_ALL')!!}
																handleChange={handleSelectAllChange}
																disabled={
																	mode !== MODE_VALUES.ACTIVATE &&
																	mode !== MODE_VALUES.DEACTIVATE
																}
															/>
														</div>
														<ListView
															onScroll={scrollHandler}
															data={weblinkData}
															item={MyCustomStateCell}
															className='po-weblink-wrap'
														/>
													</fieldset>
												</div>
											</div>
										</div>

										<div className='k-form-buttons default-weblink-footer'>
											{(mode === MODE_VALUES.ACTIVATE ||
												mode === MODE_VALUES.DEACTIVATE) &&
												hasPrivileges(
													Privileges.weblinkActivateDeactivate,
													getLoginUserData,
												) && (
													<CustomhtmlButton
														type='button'
														onClick={() => handleSubmit()}
														disabled={loadingUpdateWeblinkStatus}
														buttonClassName='btn btn-primary h-44px'>
														{loadingUpdateWeblinkStatus ? (
															<CustomLoader />
														) : (
															t(
																`${
																	mode === MODE_VALUES.DEACTIVATE
																		? 'LABEL_DEACTIVATE'
																		: 'LABEL_ACTIVATE'
																}`,
															)
														)}
													</CustomhtmlButton>
												)}
											{mode === 'review' && (
												<>
													{hasPrivileges(
														Privileges.weblinkReject,
														getLoginUserData,
													) && (
														<CustomButton
															type='button'
															disabled={
																reviewLoading || rejectLoading
															}
															buttonClassName='btn btn-outline-danger h-44px '
															buttonName={
																rejectLoading ? (
																	<span className='loader-danger'>
																		<CustomLoader />
																	</span>
																) : (
																	t('REJECT')!!
																)
															}
															handleClick={handleReturn}
														/>
													)}
													{hasPrivileges(
														Privileges.weblinkReview,
														getLoginUserData,
													) && (
														<CustomButton
															disabled={
																reviewLoading || rejectLoading
															}
															type='button'
															buttonClassName='btn btn-warning h-44px '
															buttonName={
																reviewLoading ? (
																	<CustomLoader />
																) : (
																	t(
																		`${
																			isWeblinkActive
																				? 'REVIEW_DEACTIVATION'
																				: 'REVIEW_ACTIVATION'
																		}`,
																	)!!
																)
															}
															handleClick={handleReview}
														/>
													)}
												</>
											)}
											{mode === 'approve' && (
												<>
													{hasPrivileges(
														Privileges.weblinkReject,
														getLoginUserData,
													) && (
														<CustomButton
															type='button'
															disabled={
																approveLoading || rejectLoading
															}
															buttonClassName='btn btn-outline-danger h-44px'
															buttonName={
																rejectLoading ? (
																	<span className='loader-danger'>
																		<CustomLoader />
																	</span>
																) : (
																	t('REJECT')!!
																)
															}
															handleClick={handleReturn}
														/>
													)}
													{hasPrivileges(
														Privileges.weblinkApprove,
														getLoginUserData,
													) && (
														<CustomButton
															type='button'
															disabled={
																approveLoading || rejectLoading
															}
															buttonClassName='btn btn-success h-44px '
															buttonName={
																approveLoading ? (
																	<CustomLoader />
																) : (
																	t(
																		`${
																			isWeblinkActive
																				? 'APPROVE_DEACTIVATION'
																				: 'APPROVE_ACTIVATION'
																		}`,
																	)!!
																)
															}
															handleClick={handleApprove}
														/>
													)}
												</>
											)}
										</div>
									</form>
								) : (
									<NoData />
								)}
							</>
						)}
					</div>
				</div>
			</div>

			{isPasswordVerification &&
				(type === WEBLINK_TYPE.PRODUCT_ORDER
					? modulesRequireAuth.includes(MODULE_IDS.PRODUCT_ORDER_MANAGEMENT)
					: modulesRequireAuth.includes(MODULE_IDS.PRODUCT_MANAGEMENT)) && (
					<PasswordVerification
						onVerificationSuccess={handleVerificationSuccess}
						toggleDialog={handleToggle}
						actions={passwordVerificationActions}
						transactions={
							type === WEBLINK_TYPE.PRODUCT_ORDER
								? PASSWORD_VERIFICATION_TRANSACTIONS.POWEBLINK
								: PASSWORD_VERIFICATION_TRANSACTIONS.PRODUCTWEBLINK
						}
						referenceId={encPOId}
					/>
				)}
		</>
	);
};
export default memo(WeblinkPage);
