import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: any = {
	productFileCreatedDateValue: '',
	productFileCreatedDateFilterValue: '',
};

export const productFileManagementSlice = createSlice({
	name: 'productFileManagementModule',
	initialState,
	reducers: {
		setProductFileCreatedDateValue: (state, action: PayloadAction<any>) => {
			state.productFileCreatedDateValue = action.payload;
		},
		setProductCreatedDateFilterValue: (state, action: PayloadAction<any>) => {
			state.productFileCreatedDateFilterValue = action.payload;
		},
	},
});

export const { setProductFileCreatedDateValue, setProductCreatedDateFilterValue } =
	productFileManagementSlice.actions;

export const getProductFileCreatedDateValue = (state: RootState) =>
	state.productFileManagementModule.productFileCreatedDateValue;
export const getProductCreatedDateFilterValue = (state: RootState) =>
	state.productFileManagementModule.productFileCreatedDateFilterValue;

export default productFileManagementSlice.reducer;
